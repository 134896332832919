import React from 'react'
import './description.css'
import { useLocation } from 'react-router-dom';

import { AiOutlineClockCircle } from 'react-icons/ai'
import ApplyNow from '../components/Common/ApplyNow';


export default function Description() {

    const location = useLocation();
    // console.log(location);

    return (
        <>

            {/* Banner */}
            <div className="solution-banner position-relative">
                <img src={require('../images/career-banner.png')} width='100%' alt="Banner" className="img-fluid" />
                <div className="banner-inner text-center text-white">
                <h1 className='mb-lg-4'>Careers</h1>
                <p>We have come together to build something that brings change to the world. Come and join us in this revolution of change through IT.</p>
                </div>
            </div>

            {/* Content  */}
            <div className="container d-flex flex-wrap my-5">

                <div className="col-lg-7 col-12 border rounded px-3 py-4" style={{ height : 'auto' }}>
                    <h2 className='mb-3'>{location.state.data.position}</h2>
                    <div className='pb-4'>
                        <h4>Job Summary:</h4>
                        <p>{location.state.data.summary}</p>
                    </div>
                    <div className="pb-4">
                        <h4>Key Responsibilities:</h4>
                        {
                            location.state.data.responsibilities.map((data, i)=>{
                                return(
                                    <p key={i}>- {data.point}</p>
                                )
                            })
                        }
                    </div>
                    <div className="pb-4">
                        <h4>Qualifications:</h4>
                        {
                            location.state.data.qualifications.map((data, i)=>{
                                return(
                                    <p key={i}>- {data.point}</p>
                                )
                            })
                        }
                    </div>
                    <p className='mb-4'>{location.state.data.shortTxt}</p>
                    <p className='description-svg fw-medium mb-lg-5'>
                        <AiOutlineClockCircle className='me-md-3 me-1' />
                        <span className='pe-md-5 pe-3 fs-5'>{location.state.data.mode}</span>
                    </p>
                </div>

                <div className="col-lg-5 col-md-10 col-11 mx-auto px-lg-5 px-md-3 mt-lg-1 mt-5">
                    <h3 className='mb-2'>Apply Now</h3>
                    <div className="mb-4">
                        <ApplyNow />
                    </div>
                </div>

            </div>
        </>
    )
}
