
export const TestimonialItems = [
  {
    img: require("../../images/testimonialsImages/chhavi-studios.png"),
    name: "Ar. Srushti G Memane",
    site: "Chhavi Studios",
    text: "Datart solutions is the ultimate stop for me. They have designed a very elegant and minimal styled website for my architectural studio. They have tailored the website with details and well put our thoughts that would easily reach out to the audience. Thank you Yash for my much awaited website!",
    stars: 5,
  },
  {
    img: require("../../images/testimonialsImages/sanjoli-sarees.png"),
    name: "Vinod Agarwal",
    site: "LaxmiHari Prints Pvt Ltd",
    text: "We are incredibly pleased with Datart Solutions for translating our brand's essence into a user-friendly website. Their expertise in web design and development has transformed our brand into a digital masterpiece. The interface, quick loading times, and seamless checkout process have significantly improved our customer's online shopping experience",
    stars: 5,
  },
  {
    img: require("../../images/testimonialsImages/ap-enterprises.png"),
    name: "Amit Jaware",
    site: "AP Enterprises",
    text: "I'm absolutely delighted to express my appreciation for Datart Solutions. Their craft in creating invoice generating website for our company was truly remarkable. They have handled everything from delivering a minute iteration to the responsive design development with complete professionalism. I would recommend working with them for better results.",
    stars: 5,
  },
  {
    img: require("../../images/testimonialsImages/shrinivas.png"),
    name: "Shrinivas Balwadkar (CTO)",
    site: "Kaash Studio",
    text: "It was incredible working with Datart Solutions, I highly recommend them for any web and mobile development. Their team is highly skilled, professional, and efficient. They listened attentively to our requirements and delivered a stunning website that exceeded our expectations. Most importantly, they did not add any hidden/extra charges and delivered the website on time.",
    stars: 5,
  },
  {
    img: require("../../images/testimonialsImages/SSlogotext.png"),
    name: "Mandar Rachalwar",
    site: "SS Service",
    text: "Datart Solutions delivered exactly according to our requirement with an easy-to-use interface. We are impressed by the reliability and performance as they maintained open communication and collaborated closely with our team. It has helped us manage our operations and quality. They are a great team to work with. Highly recommended for any businesses seeking IT solutions.",
    stars: 5,
  },
];

