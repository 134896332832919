import { useEffect, useRef } from 'react';
import './CssSwiper.css';

const CssSwiper = () => {
    const logosRef = useRef(null);

    useEffect(() => {
        const logosSlide = logosRef.current.children[0];
        const copy = logosSlide.cloneNode(true);
        logosRef.current.appendChild(copy);
    }, []);

    return (
      <>
        <div className="text-center  mt-3 mt-md-5 pt-lg-5 mx-md-0 mx-5">
          <h1>Client Spotlights</h1>
          <p className="text-secondary py-3 p-1 pforimac">
            Showcasing our exceptional partnerships, inspiring growth and
            innovation.
          </p>
        </div>
        <hr />
        <div className="logos" ref={logosRef}>
          <div className="logos-slide">
            <img
              src={require("../../images/homeImages/p2slogo.png")}
              alt="Logo 1"
            />
            <img
              src={require("../../images/homeImages/additivelogo.png")}
              alt="Logo 1"
            />
            <img
              src={require("../../images/homeImages/Izelogo.png")}
              alt="Logo 1"
            />
            <img
              src={require("../../images/homeImages/kashlogo.png")}
              alt="Logo 1"
            />
            <img
              src={require("../../images/homeImages/ignislogo.png")}
              alt="Logo 1"
            />
            <img
              src={require("../../images/homeImages/sanjolilogo1.png")}
              alt="Logo 1"
            />
            <img
              src={require("../../images/homeImages/FinexaCapital.png")}
              alt="Logo 1"
            />
            <img
              src={require("../../images/homeImages/chhavilogo.png")}
              alt="Logo 1"
            />
            <img
              src={require("../../images/homeImages/SSlogo.png")}
              alt="Logo 1"
            />
            <img
              src={require("../../images/homeImages/claaylogo.png")}
              alt="Logo 1"
            />
            <img
              src={require("../../images/homeImages/logo5.png")}
              alt="Logo 1"
            />
            <img
              src={require("../../images/homeImages/logo10.png")}
              alt="Logo 1"
            />
            <img
              src={require("../../images/homeImages/logo14.jpg")}
              alt="Logo 1"
            />
            <img
              src={require("../../images/homeImages/Aatmanlogo.png")}
              alt="logo 1"
            />
            <img
              src={require("../../images/homeImages/YahawaterLogo.png")}
              alt="logo 1"
            />
            <img
              src={require("../../images/homeImages/Yandelogo.png")}
              alt="logo 1"
            />
            <img
              src={require("../../images/homeImages/Vedantlogo.png")}
              alt="logo 1"
            />
          </div>
         
        </div>
        <hr className="mb-5" />
      </>
    );
};

export default CssSwiper;