
export const CustomerItems = [
  {
    img: require("../../images/SSimg1.png"),
    subTitle: "Erosion Monitoring WebApp",
    title: "SS Services",
    para: [
      {
        p: "We have engineered a state-of-the-art IoT device and accompanying web application to revolutionize erosion monitoring. Our cutting-edge solution provides real-time data and advanced analytics, ensuring precise monitoring and effective management of erosion in various environments. The custom-built IoT device is equipped with high-precision sensors that continuously monitor and record critical data, while the integrated web application serves as the central hub for data visualization and analysis, offering users a detailed overview of erosion trends through comprehensive metrics and analytics",
      },
      {
        p: "",
      },
    ],
    techs:
      "Figma, HTML, CSS, React JS, Node JS, MongoDB, Google cloud platform",
  },
  {
    img: require("../../images/ize11.png"),
    subTitle: "Print on demand E-commerce",
    title: "IZE",
    para: [
      // {
      //   p: "IZE, a Print-on-Demand E-commerce platform empowering artists and designers to showcase their creations and merchandise in a lifelike 3D environment. The platform caters to both artists and customers, offering them distinct features to navigate the site efficiently.",
      // },{}
      {
        p: "At IZE, we developed a cutting-edge Print-on-Demand e-commerce platform that empowers artists and designers to showcase their creations in a lifelike 3D environment. The platform offers distinct features for both artists and customers, ensuring an efficient and engaging experience while navigating the site. With its intuitive interface and advanced visualization tools, IZÉ makes it easy for users to explore, customize, and purchase unique products directly from their favorite creators.",
      },
      {
        p: "",
      },
    ],
    techs:
      "Figma, HTML, CSS, React JS, Node JS, MongoDB, Google cloud platform",
  },
  {
    img: require("../../images/Path2study1.png"),
    subTitle: "Educational Service Provider",
    title: "Path2study",
    para: [
      // {
      //   p: "The primary goal of the Path2Study is to provide scholarship and student loan information in an intuitive, user-friendly  way that caters to the needs of students seeking financial assistance for their education.The project aims to address the challenges students face when navigating scholarship and loan options, providing them with a comprehensive resource to make well-informed decisions.",
      // },
      {
        p: "At Path2Study, we aim to provide scholarship and student loan information in an intuitive, user-friendly way that caters to students seeking financial assistance for their education. Our platform addresses the challenges students face when navigating scholarship and loan options, offering a comprehensive resource to help them make well-informed decisions about their financial future. With personalized tools and resources, Path2Study empowers students to explore various funding opportunities, ensuring they can pursue their educational goals with confidence.",
      },

      // {
      //   p: "The project aims to address the challenges students face when navigating scholarship and loan options, providing them with a comprehensive resource to make well-informed decisions.",
      // },
    ],
    techs:
      "Figma, HTML, CSS, React JS, Node JS, MongoDB, Google cloud platform",
  },
  {
    img: require("../../images/amimg1.png"),
    subTitle: "3D Printing and Designing",
    title: "The Additive Minds",
    para: [
      // {
      //   p: "The Additive Minds is a 3D printing and manufacturing service provider specialized in precision and quality. They are specialized in providing services in commercial sector of the industry. The brand integrates advanced technologies and innovative processes to deliver high-quality prototypes, products, and components with precision and efficiency.",
      // },
      {
        p:"Additive Minds is a 3D printing and manufacturing service provider specializing in precision and quality for the commercial sector. They integrate advanced technologies and innovative processes to deliver high-quality prototypes, products, and components with exceptional efficiency.By focusing on the latest 3D printing technologies, Additive Minds ensures tailored solutions that meet the specific needs of their clients."
      },

      // {
      //   p: "The project aims to address the challenges students face when navigating scholarship and loan options, providing them with a comprehensive resource to make well-informed decisions.",
      // },
    ],
    techs:
      "Figma, HTML, CSS, React JS, Node JS, MongoDB, Google cloud platform",
  },

  {
    img: require("../../images/kashimg1.png"),
    subTitle: "Kaash Studio",
    title: "Kaash Studio",
    para: [
      // {
      //   p: "Kaash Studio is a 3D printing hub that offers bespoke solutions marked by unparalleled quality and unwavering commitment to two dynamic industries. They combine expertise, innovation, and state-of-the-art technology to bring your ideas to life in stunning detail offering two distinct services in  interior and commercial sector. ",
      // },
      {
        p: "Kaash Studio is a 3D printing hub that offers bespoke solutions characterized by unparalleled quality and a strong commitment to two dynamic industries. By combining expertise, innovation, and state-of-the-art technology, they bring ideas to life in stunning detail, providing tailored services for both the interior and commercial sectors.        With a focus on precision and creativity, Kaash Studio ensures that every project meets the highest standards, and their dedication to excellence positions them as a trusted partner in the 3D printing landscape",
      },
    ],
    techs:
      "Figma, HTML, CSS, React JS, Node JS, MongoDB, Google cloud platform",
  },
  {
    img: require("../../images/finexca1.png"),
    subTitle: "Fintech Webapp",
    title: "Finexa Capital",
    para: [
      // {
      //   p: "Finexa Capital aimed to streamline financial services, foster closer client relationships, and display their comprehensive loan portfolio, wide range of SME services with a one-stop solution for diverse customer needs.",
      // },
      {
        p: "Finexa Capital aimed to streamline financial services, foster closer client relationships, and showcase their comprehensive loan portfolio along with a wide range of SME services. The platform serves as a one-stop solution for diverse customer needs, highlighting Finexa's expertise in providing tailored financial solutions.By leveraging cutting-edge technology and a customer-centric approach, Finexa Capital empowers clients to navigate the complex financial landscape with confidence.",
      },
    ],
    techs: "Figma, HTML, CSS, React JS",
  },
  {
    img: require("../../images/Chavi1.png"),
    subTitle: "Interior Design and Architecture",
    title: "Chhavi Studio",
    para: [
      // {
      //   p: "Studio Chhavi sought a way to streamline their design processes, enhance client collaboration, and present their portfolio in an engaging manner through intuitive user interfaces.",
      // },
      {
        p:"Studio Chhavi sought to streamline their design processes, enhance client collaboration, and present their portfolio in an engaging manner through intuitive user interfaces. We developed a digital solution that aligns with their vision of elegance and innovation, providing a seamless experience for both clients and designers.By integrating advanced design tools and features, Chhavi Studio can now showcase their work effectively while fostering stronger relationships with clients"
      },
      {
        p: "A digital solution that aligned with their vision of elegance and innovation.",
      },
    ],
    techs: "Figma, HTML, CSS, React JS",
  },
  {
    img: require("../../images/Sanjoli11.png"),
    subTitle: "E-commerce ",
    title: "Sanjoli Sarees",
    para: [
     
      {
        p: "Sanjoli Sarees, the leading saree brand in Gujarat, was redesigned and successfully relaunched on June 20th. The project utilized HTML, CSS, React, MongoDB, and Google Cloud to create a modern, user-friendly website that enhances the shopping experience for customers. The website features a clean layout, high-quality product images, and a secure checkout process.",
      },
      {
        p: "It is one of the leading saree brands in Gujarat.",
      },
    ],
    techs:
      "Figma, HTML, CSS, React JS, Node JS, MongoDB, Google cloud platform",
  },

  {
    img: require("../../images/Ap11.png"),
    subTitle: "Invoicing Webapp",
    title: "AP Enterprises",
    para: [
      // {
      //   p: '"A P Enterprises" is a client specific user-friendly web app for efficient invoice management, enabling the admin to generate, edit, and track invoices, while also providing a secure PDF generation and download functionalities.',
      // },
      {
        p: "A P Enterprises is a client-specific, user-friendly web app designed for efficient invoice management. It enables the admin to generate, edit, and track invoices seamlessly while providing secure PDF generation and download functionalities.This solution streamlines the invoicing process, ensuring that businesses can manage their financial transactions with ease and accuracy. ",
      },
    ],
    techs:
      "Figma, HTML, CSS, React JS, Node JS, MongoDB, Google cloud platform",
  },

];

