import React from 'react'
import { useState } from 'react';
import { useEffect,useRef } from 'react';
import './Customsoftdevelopment.css';
import CardUi from '../components/Common/CardUi';
import { customData } from '../components/Common/CardwebData';
import { FaArrowTrendUp } from "react-icons/fa6";
import CssSwiper from '../components/Common/CssSwiper';
import { gsap } from "gsap";
import { useNavigate } from 'react-router-dom';
import Expertise from '../components/Common/Expertise';
import Static from './Static';
import { Helmet } from 'react-helmet';


function Customsoftdevelopment() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []); // Empty dependency array ensures the effect runs on

  useEffect(() => {
    gsap.to(logoRef.current, {
      y: () => document.querySelector(".scroll-container").clientHeight - 100,
      ease: "none",
      scrollTrigger: {
        trigger: ".scroll-container",
        start: "top top",
        end: "bottom bottom",
        scrub: true,
      },
    });
  }, []);
  const logoRef = useRef(null);

   const navigate = useNavigate();

   const handleContact = () => {
     navigate("/contact-us");
   };

  return (
    <div>
      <Helmet>
        <title>Custom Software Development Solutions | Datart Infotech</title>
        <meta
          name="description"
          content="Transform your business with our custom software development services. At Datart Infotech, we provide scalable, secure, and tailored solutions to meet your unique business needs. Contact Us for Free Consultation!"
        />
      </Helmet>
      {/* bannersection */}
      <div className="custombanner d-md-block d-none">
        <div className="custombox  text-center ">
          <h1>
            <span className="texthead">Custom Software Solutions</span> Tailored
            <br /> to Your <span className="texthead">Business Needs</span>
          </h1>
          <p className="mt-md-4 text-secondary px-5">
            We specialize in building scalable and secure custom software that
            empowers businesses to achieve operational efficiency. From
            enterprise solutions to SaaS platforms, our expertise ensures you
            get software that perfectly aligns with your goals.
          </p>
          <div className="d-flex align-items-center justify-content-center ">
            <div
              className="btn border col-md-3  mt-md-4 mt-3 rounded-5 abobutton"
              onClick={handleContact}
            >
              Contact us
            </div>
            {/* <div onClick={handleContact}> */}
            <img
              src={require("../images/homeImages/aboutround.png")}
              className="img-fluid   mt-md-4 mt-3 "
              style={{ width: "40px" }}
              onClick={handleContact}
            />
            {/* </div> */}
          </div>
        </div>
      </div>

      {windowWidth < 480 && (
        <>
          <div className="custommob ">
            <div className="custombox  text-center ">
              <h1>
                <span className="texthead">Custom Software Solutions</span>{" "}
                Tailored
                <br /> to Your <span className="texthead">Business Needs</span>
              </h1>
              <p className="mt-3 text-secondary">
                We specialize in building scalable and secure custom software
                that empowers businesses to achieve operational efficiency. From
                enterprise solutions to SaaS platforms, our expertise ensures
                you get software that perfectly aligns with your goals.
              </p>
              <div className="d-flex align-items-center justify-content-center  mt-3">
                <div
                  className="btn border col-md-3 col-6  mt-md-4 mt-3 rounded-5 abobutton"
                  onClick={handleContact}
                >
                  Contact us
                </div>
                {/* <div onClick={handleContact}> */}
                <img
                  src={require("../images/homeImages/aboutround.png")}
                  className="img-fluid   mt-md-4 mt-3 "
                  style={{ width: "40px" }}
                  onClick={handleContact}
                />
                {/* </div> */}
              </div>
            </div>
          </div>
        </>
      )}

      <div
        className="scroll-container  d-md-block d-none"
        style={{ height: "1650px", position: "relative" }}
      >
        <div
          style={{
            position: "absolute",
            left: "35px",
            top: "60px",
            bottom: "0px",
            width: "7px",
            backgroundColor: "#DAF0FF",
            zIndex: 1,
          }}
        >
          <div
            ref={logoRef}
            style={{
              width: "32px",
              height: "32px",
              backgroundColor: "#DAF0FF",
              borderRadius: "50%",
              position: "absolute",
              top: "0px",
              bottom: "0px",
              left: "-13px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: "#fff",
              fontWeight: "bold",
              zIndex: 2,
            }}
          >
            <img
              src={require("../images/datartswipe.png")}
              className="img-fluid"
            />
          </div>
        </div>
        {/* customdevelopment */}
        <div className="content" style={{ marginLeft: "100px" }}>
          <div className="p-md-5 pb-md-0 p-3">
            <h3>Custom development</h3>
            <img
              src={require("../images/customdevban.png")}
              alt="Webdev banner"
              className="col-12 pb-md-0"
            />
          </div>
          <CardUi dataarr={customData} />
          {/* stats */}
          <Static />
          {/* expertise */}
          <Expertise />
          {/* it ecosystem */}
        </div>
      </div>

      {/* for mob */}
      {windowWidth < 480 && (
        <div>
          <div className="p-md-5 pb-md-0 p-3">
            <h3>Custom development</h3>
            <img
              src={require("../images/customdevban.png")}
              alt="Webdev banner"
              className="col-12 pb-md-0"
              style={{ height: "150px" }}
            />
          </div>
          <CardUi dataarr={customData} />
          {/* stats */}
          <Static />

          {/* expertise */}
          <Expertise />
          {/* it ecosystem */}
        </div>
      )}

      {/* client */}
      <CssSwiper />
    </div>
  );
}

export default Customsoftdevelopment