import React from 'react'
import './ImageSlider.css'

import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, Autoplay, Pagination, Scrollbar, A11y } from 'swiper/modules'

import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/scrollbar'
import 'swiper/css/autoplay'

export default function ImageSlider({ popupImg }) {
    return(
        <>
            <Swiper
                modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
                spaceBetween={0}
                slidesPerView={1}
                autoplay={{delay : 5000}}
                // navigation
                pagination={{ clickable : true }}
                // scrollbar={{ draggable: true }}
                className='projects-slider position-relative'
                >
                    {
                        popupImg ?
                        popupImg.map((data, i)=>{
                            return(
                                <SwiperSlide>
                                    <div className="">
                                        <img src={data.pop} alt="Slider" className="img-fluid w-100" />
                                    </div>
                                </SwiperSlide>
                            )
                        })
                        :
                        <></>
                    }
                    
                </Swiper>
        </>
    )
}
