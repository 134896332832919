import React from 'react'
import './Mobdevelopment.css'
import CardUi from '../components/Common/CardUi';
import { Mobdata } from '../components/Common/CardwebData';
import CssSwiper from "../components/Common/CssSwiper";
import { FaArrowTrendUp } from "react-icons/fa6";
import icon from "../images/homeImages/icon-stats.png";
import { useState } from 'react';
import { useEffect,useRef } from 'react';
import { gsap } from "gsap";
import { useNavigate } from 'react-router-dom';
import Expertise from '../components/Common/Expertise';
import Static from './Static';
import { Helmet } from 'react-helmet';

function Mobdevelopment() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []); // Empty dependency array ensures the effect runs on

useEffect(() => {
  gsap.to(logoRef.current, {
    y: () => document.querySelector(".scroll-container").clientHeight - 100,
    ease: "none",
    scrollTrigger: {
      trigger: ".scroll-container",
      start: "top top",
      end: "bottom bottom",
      scrub: true,
    },
  });
}, []);
const logoRef = useRef(null);
  const navigate = useNavigate();
  const handleContact = () => {
    navigate("/contact-us");
  };

  return (
    <div>
      <Helmet>
        <title>
          {" "}
          Datart Infotech | Cutting-Edge Mobile Application Development Services
        </title>
        <meta
          name="description"
          content="Unlock the potential of your business with custom mobile application development. Datart Infotech offers scalable solutions for iOS, Android, and cross-platform apps. Contact Us Today!"
        />
      </Helmet>
      {/* bannersection */}
      <div className="bannerrole d-md-block d-none">
        <div className="d-flex flex-wrap align-items-center mobbanner">
          <div className="col-md-7 ">
            <div className="webbanner-box ">
              <h1 className=" textheadingweb">
                {/* Driving Excellence Through Customized Solutions */}
                Building Powerful Mobile Apps for a{" "}
                <span className="texthead">Digital-First World</span>
              </h1>

              <p className="mt-md-4 textwebpara text-muted">
                We specialize in creating mobile applications that drive growth
                and user engagement. Our solutions are tailored for iOS,
                Android, and hybrid platforms, ensuring seamless performance and
                user experience across all devices.
              </p>
              <div className="d-flex">
                <div
                  className="btn border col-3  mt-md-4 rounded-5 abobutton"
                  onClick={handleContact}
                >
                  Contact us
                </div>
                <div onClick={handleContact}>
                  <img
                    src={require("../images/homeImages/aboutround.png")}
                    className="img-fluid  col-7 mt-md-4 "
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-5  ">
            <img
              src={require("../images/mobbannerside.png")}
              className="img-fluid  p-md-2 pb-md-0"
            />
          </div>
        </div>
      </div>

      {windowWidth < 480 && (
        <>
          <div className="webbaner">
            <div className="p-5 mx-3 pb-0">
              <h1 className="" style={{ textAlign: "center" }}>
                Building Powerful Mobile Apps for a{" "}
                <span className="texthead">Digital-First World</span>
              </h1>
            </div>
            <div>
              <img
                src={require("../images/mobbannerside.png")}
                className="img-fluid p-3 "
                alt="img"
              />
            </div>
            <p
              className="text-secondary px-4 mt-2"
              style={{ textAlign: "justify" }}
            >
              We specialize in creating mobile applications that drive growth
              and user engagement. Our solutions are tailored for iOS, Android,
              and hybrid platforms, ensuring seamless performance and user
              experience across all devices.
            </p>

            <div className="d-flex justify-content-center align-items-center mt-3">
              <div
                className="btn border col-5  mt-md-4 rounded-5 abobutton"
                onClick={handleContact}
              >
                Contact us
              </div>
              <div onClick={handleContact}>
                <img
                  src={require("../images/homeImages/aboutround.png")}
                  className="img-fluid  col-7 mt-md-4 "
                />
              </div>
            </div>
          </div>
        </>
      )}

      <div
        className="scroll-container d-md-block d-none"
        style={{ height: "1650px", position: "relative" }}
      >
        <div
          style={{
            position: "absolute",
            left: "35px",
            top: "60px",
            bottom: "0px",
            width: "7px",
            backgroundColor: "#DAF0FF",
            zIndex: 1,
          }}
        >
          <div
            ref={logoRef}
            style={{
              width: "32px",
              height: "32px",
              backgroundColor: "#DAF0FF",
              borderRadius: "50%",
              position: "absolute",
              top: "0px",
              bottom: "0px",
              left: "-13px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: "#fff",
              fontWeight: "bold",
              zIndex: 2,
            }}
          >
            <img
              src={require("../images/datartswipe.png")}
              className="img-fluid"
            />
          </div>
        </div>
        <div className="content" style={{ marginLeft: "100px" }}>
          <div className="p-md-5 pb-md-0">
            <h3>Mobile App Development </h3>
            <img
              alt="mobdev banner"
              className="col-12 pb-md-0"
              src={require("../images/mobdevbanner.png")}
            />
          </div>
          <CardUi dataarr={Mobdata} />
          {/* stats */}
          <Static />
          {/* expertise */}
          <Expertise />
          {/* it ecossytem */}
        </div>
      </div>
      {/* client */}

      {/* for mobile */}
      {windowWidth < 480 && (
        <div className="p-2">
          <div className="p-md-5 pb-md-0">
            <h3>Mobile App Development </h3>
            <img
              alt="mobdev banner"
              className="col-12 pb-md-0"
              src={require("../images/mobdevbanner.png")}
              style={{ height: "150px" }}
            />
          </div>
          <CardUi dataarr={Mobdata} />
          {/* stats */}
          <Static />

          {/* expertise */}
          <Expertise />
        </div>
      )}
      <CssSwiper />
    </div>
  );
}

export default Mobdevelopment