import React from 'react'

function BlankPage() {
  return (
    <div className="container p-md-4">
      <div className="col-md-11 mx-md-5">
        <p style={{ textAlign: "justify" }}>
          <h2>Privacy Policy</h2>
          <br /> Last updated: September 25, 2024
          <br /> This Privacy Policy describes our policies and procedures on
          the collection, use and disclosure of your information when you use
          the service and tells you about your privacy rights and how the law
          protects you.
          <br /> We use your personal data to provide and improve the service.
          By using the service, you agree to the collection and use of
          information in accordance with this privacy policy.
          <br />
          <h5 className="mt-3">Interpretation and Definitions</h5>
          <h6 className="fs-5">Interpretation</h6>
          The words of which the initial letter is capitalized have meanings
          defined under the following conditions. The following definitions
          shall have the same meaning regardless of whether they appear in
          singular or in plural.
          <br />
          <h5 className="mt-3">Definitions</h5>
          For the purposes of this Privacy Policy:
          <div className="">
            <ul>
              <li>
                {" "}
                <b>Account</b> means a unique account created for You to access
                our Service or parts of our Service.
              </li>
              <li>
                {" "}
                <b>Affiliate</b> means an entity that controls, is controlled by
                or is under common control with a party, where "control" means
                ownership of 50% or more of the shares, equity interest or other
                securities entitled to vote for election of directors or other
                managing authority.
              </li>
              <li>
                {" "}
                <b>Company </b>(referred to as either "the Company", "We", "Us"
                or "Our" in this Agreement) refers to Datart Infotech LLP, 203,
                Pentagon 2, Magarpatta, Hadapsar-411028.
              </li>
              <li>
                {" "}
                <b> Cookies </b>are small files that are placed on your
                computer, mobile device or any other device by a website,
                containing the details of your browsing history on that website
                among its many uses.
              </li>
              <li>
                {" "}
                <b> Country </b>refers to: Maharashtra, India
              </li>
              <li>
                {" "}
                <b>Device </b>means any device that can access the Service such
                as a computer, a cellphone or a digital tablet.
              </li>
              <li>
                {" "}
                <b>Personal Data</b> is any information that relates to an
                identified or identifiable individual.
              </li>
              <li>
                {" "}
                <b> Service </b>refers to the Website.
              </li>
              <li>
                {" "}
                <b>Service Provider</b> means any natural or legal person who
                processes the data on behalf of the Company. It refers to
                third-party companies or individuals employed by the Company to
                facilitate the Service, to provide the Service on behalf of the
                Company, to perform services related to the Service or to assist
                the Company in analyzing how the Service is used.
              </li>
              <li>
                <b>Usage Data</b> refers to data collected automatically, either
                generated by the use of the Service or from the Service
                infrastructure itself (for example, the duration of a page
                visit).
              </li>
              <li>
                <b>Website </b>refers to Datart Infotech, accessible from
                https://datartinfotech.com
              </li>
              <li>
                <b>You</b> means the individual accessing or using the Service,
                or the company, or other legal entity on behalf of which such
                individual is accessing or using the Service, as applicable.
              </li>
            </ul>
          </div>
          <h5 className="mt-3">Collecting and Using your Personal Data </h5>
          <h6>Types of Data Collected: </h6>
          <b>Personal Data</b>
          <p>
            While using our service, We may ask you to provide us with certain
            personally identifiable information that can be used to contact or
            identify You. Personally identifiable information may include, but
            is not limited to:
          </p>{" "}
          <br />
          <b> Usage Data </b>
          <br />
          Usage Data is collected automatically when using the Service.
          <br />
          Usage Data may include information such as your Device's Internet
          Protocol address (e.g. IP address), browser type, browser version, the
          pages of our Service that You visit, the time and date of your visit,
          the time spent on those pages, unique device identifiers and other
          diagnostic data.
          <br />
          When You access the Service by or through a mobile device, We may
          collect certain information automatically, including, but not limited
          to, the type of mobile device You use, your mobile device unique ID,
          the IP address of your mobile device, Your mobile operating system,
          the type of mobile Internet browser You use, unique device identifiers
          and other diagnostic data.
          <br /> We may also collect information that your browser sends
          whenever You visit our Service or when You access the Service by or
          through a mobile device.
          <br />
          
          <h5 className="mt-3">Use of your Personal Data</h5>
          The Company may use Personal Data for the following purposes:
          <br />
          <ul>
            <li>
              <b>To provide and maintain our Service, </b>
              including to monitor the usage of our Service.
            </li>
            <li>
              {" "}
              <b>To manage your Account: </b>
              to manage your registration as a user of the Service. The Personal
              Data You provide can give You access to different functionalities
              of the Service that are available to You as a registered user.
            </li>
            <li>
              {" "}
              <b>For the performance of a contract:</b> the development,
              compliance and undertaking of the purchase contract for the
              products, items or services You have purchased or of any other
              contract with Us through the Service.
            </li>
            <li>
              {" "}
              <b> To contact You:</b> To contact You by email, telephone calls,
              SMS, or other equivalent forms of electronic communication, such
              as a mobile application's push notifications regarding updates or
              informative communications related to the functionalities,
              products or contracted services, including the security updates,
              when necessary or reasonable for their implementation.
            </li>
            <li>
              <b>To provide You</b> with news, special offers and general
              information about other goods, services and events which we offer
              that are similar to those that you have already purchased or
              enquired about unless You have opted not to receive such
              information.
            </li>
            <li>
              <b>To manage your requests:</b> To attend and manage your requests
              to Us.
            </li>
            <li>
              <b>For business transfers:</b> We may use your information to
              evaluate or conduct a merger, divestiture, restructuring,
              reorganization, dissolution, or other sale or transfer of some or
              all of Our assets, whether as a going concern or as part of
              bankruptcy, liquidation, or similar proceeding, in which Personal
              Data held by Us about our Service users is among the assets
              transferred.
            </li>
            <li>
              <b>For other purposes:</b> We may use your information for other
              purposes, such as data analysis, identifying usage trends,
              determining the effectiveness of our promotional campaigns and to
              evaluate and improve our Service, products, services, marketing
              and your experience.
            </li>
          </ul>
          We may share your personal information in the following situations:{" "}
          <br />
          <ul>
            <li>
              {" "}
              <b>With Service Providers:</b> We may share your personal
              information with Service Providers to monitor and analyze the use
              of our Service, to contact You.
            </li>
            <li>
              <b> For business transfers:</b> We may share or transfer your
              personal information in connection with, or during negotiations
              of, any merger, sale of Company assets, financing, or acquisition
              of all or a portion of Our business to another company.
            </li>
            <li>
              <b> With Affiliates:</b> We may share your information with Our
              affiliates, in which case we will require those affiliates to
              honor this Privacy Policy. Affiliates include Our parent company
              and any other subsidiaries, joint venture partners or other
              companies that We control or that are under common control with
              Us.
            </li>
            <li>
              <b> With business partners: </b> We may share your information
              with Our business partners to offer You certain products, services
              or promotions.
            </li>
            <li>
              <b> With other users:</b> when you share personal information or
              otherwise interact in the public areas with other users, such
              information may be viewed by all users and may be publicly
              distributed outside.
            </li>
            <li>
              {" "}
              <b> With your consent:</b> We may disclose your personal
              information for any other purpose with your consent.
            </li>
          </ul>
          <h6 className="mt-2 fw-bold">Retention of your Personal Data</h6> The
          Company will retain your Personal Data only for as long as is
          necessary for the purposes set out in this Privacy Policy. We will
          retain and use your Personal Data to the extent necessary to comply
          with our legal obligations (for example, if we are required to retain
          your data to comply with applicable laws), resolve disputes, and
          enforce our legal agreements and policies.
          <br />
          The Company will also retain Usage Data for internal analysis
          purposes. Usage Data is generally retained for a shorter period of
          time, except when this data is used to strengthen the security or to
          improve the functionality of Our Service, or We are legally obligated
          to retain this data for longer time periods.
          <br />
          <h6 className="mt-2 fw-bold"> Transfer of your Personal Data</h6>
          your information, including Personal Data, is processed at the
          Company's operating offices and in any other places where the parties
          involved in the processing are located. It means that this information
          may be transferred to — and maintained on — computers located outside
          of You our state, province, country or other governmental jurisdiction
          where the data protection laws may differ than those from your
          jurisdiction.
          <br /> your consent to this Privacy Policy followed by your submission
          of such information represents your agreement to that transfer.
          <br /> The Company will take all steps reasonably necessary to ensure
          that your data is treated securely and in accordance with this Privacy
          Policy and no transfer of your Personal Data will take place to an
          organization or a country unless there are adequate controls in place
          including the security of your data and other personal information.
          <br />
          <h6 className="mt-2 fw-bold">Delete Your Personal Data</h6>
          You have the right to delete or request that We assist in deleting the
          Personal Data that We have collected about You.
          <br /> Our Service may give You the ability to delete certain
          information about You from within the Service.
          <br /> You may update, amend, or delete your information at any time
          by signing in to your Account, if you have one, and visiting the
          account settings section that allows you to manage your personal
          information. You may also contact Us to request access to, correct, or
          delete any personal information that You have provided to Us.
          <br />
          Please note, however, that We may need to retain certain information
          when we have a legal obligation or lawful basis to do so.
          <br />
          <h5 className="mt-3">Disclosure of Your Personal Data</h5>
          <h6 className="fw-bold">Business Transactions</h6> If the Company is
          involved in a merger, acquisition or asset sale, your Personal Data
          may be transferred. We will provide notice before your Personal Data
          is transferred and becomes subject to a different Privacy Policy.
          <br />
          <h6 className="mt-2 fw-bold">Law enforcement</h6> Under certain
          circumstances, the Company may be required to disclose your Personal
          Data if required to do so by law or in response to valid requests by
          public authorities (e.g. a court or a government agency).
          <br />
          <h6 className="mt-2 fw-bold">Other legal requirements</h6>
          The Company may disclose your Personal Data in the good faith belief
          that such action is necessary to: <br />
          <ul>
            <li>Comply with a legal obligation </li>
            <li>Protect and defend the rights or property of the Company</li>
            <li>
              {" "}
              Prevent or investigate possible wrongdoing in connection with the
              Service
            </li>
            <li>
              Protect the personal safety of Users of the Service or the public
            </li>
            <li> Protect against legal liability </li>
          </ul>
          <h6 className="mt-2 fw-bold">Security of Your Personal Data</h6> The
          security of your Personal Data is important to Us, but remember that
          no method of transmission over the Internet, or method of electronic
          storage is 100% secure. While We strive to use commercially acceptable
          means to protect your Personal Data, We cannot guarantee its absolute
          security.
          <br />
          {/* <h5 className="mt-3"> Children's Privacy</h5> Our Service does not
          address anyone under the age of 13. We do not knowingly collect
          personally identifiable information from anyone under the age of 13.
          If You are a parent or guardian and You are aware that Your child has
          provided Us with Personal Data, please contact Us. If We become aware
          that We have collected Personal Data from anyone under the age of 13
          without verification of parental consent, We take steps to remove that
          information from Our servers.
          <br /> If We need to rely on consent as a legal basis for processing
          Your information and Your country requires consent from a parent, We
          may require Your parent's consent before We collect and use that
          information. <br /> */}
          <h5 className="mt-3">Links to Other Websites</h5>
          Our Service may contain links to other websites that are not operated
          by Us. If You click on a third party link, You will be directed to
          that third party's site. We strongly advise You to review the Privacy
          Policy of every site You visit.
          <br /> We have no control over and assume no responsibility for the
          content, privacy policies or practices of any third party sites or
          services. <br />
          <h5 className="mt-3">Changes to this Privacy Policy</h5>
          We may update Our Privacy Policy from time to time. We will notify You
          of any changes by posting the new Privacy Policy on this page.
          <br /> We will let You know via email and/or a prominent notice on Our
          Service, prior to the change becoming effective and update the "Last
          updated" date at the top of this Privacy Policy.
          <br /> You are advised to review this Privacy Policy periodically for
          any changes. Changes to this Privacy Policy are effective when they
          are posted on this page.
          <br />
          <h5 className="mt-3">Contact Us</h5> If you have any questions about
          this Privacy Policy, You can contact us: <br />
          <a
            href="mailto:hello@datartinfotech.com"
            className="text-black text-decoration-none
           "
          >
            hello@datartinfotech.com
          </a>
        </p>
      </div>
    </div>
  );
}

export default BlankPage