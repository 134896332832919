
export const Webdata = [
  {
    img: require("../../images/iconfirst.png"),
    title: "Static & Dynamic Websites",
    para: "We build both static  and dynamic websites with consistent content ensuring flexibility and engagement for your audience",
  },

  {
    img: require("../../images/iconsecond.png"),
    title: "E-commerce Development",
    para: "Leverage our expert guidance to optimize your IT strategies and stay ahead of the curve.",
  },
  {
    img: require("../../images/iconthree.png"),
    title: "Content Management Systems (CMS)",
    para: "Our CMS solutions, enabling easy content updates and management without needing to dive into code.",
  },
  {
    img: require("../../images/iconfour.png"),
    title: "Progressive Web Apps (PWA)",
    para: "Our PWAs combine the best of mobile apps and websites, providing a fast, reliable, and engaging experience even with low internet connections.",
  },
  {
    img: require("../../images/iconfive.png"),
    title: "Responsive Design & Development",
    para: "Our designs are mobile-first, ensuring seamless user experiences across all devices.",
  },
  {
    img: require("../../images/iconsix.png"),
    title: "API Integration",
    para: "Enhance your website with third-party services using API integration, creating streamlined user experiences.",
  },
  {
    img: require("../../images/iconseven.png"),
    title: "Web Performance Optimization",
    para: "We ensure fast loading times, optimized code, and minimal downtime to deliver the best performance.",
  },
  {
    img: require("../../images/iconeight.png"),
    title: "Website Maintenance & Support",
    para: "From updates to bug fixes, we offer ongoing maintenance to keep your website secure and up-to-date.",
  },
  {
    img: require("../../images/iconnine.png"),
    title: "Website Revamps",
    para: "Need a fresh look or added functionality? Our team is ready to give your website a modern makeover.",
  },
];


export const Mobdata = [
  {
    img: require("../../images/mobifirst.png"),
    title: "iOS App Development",
    para: "We specialize in creating high-quality iOS apps that leverage the full potential of Apple's ecosystem, ensuring seamless functionality and a refined user interface.",
  },

  {
    img: require("../../images/mobisecond.png"),
    title: "Android App Development",
    para: "Our Android app solutions are designed to offer excellent performance across a wide range of devices, ensuring consistency and scalability.",
  },
  {
    img: require("../../images/mobithird.png"),
    title: "Hybrid Mobile Apps",
    para: "Combining the best of both worlds, we create hybrid apps that deliver a native-like experience with reduced development time and cost.",
  },
  {
    img: require("../../images/mobifour.png"),
    title: "Wearable App Development",
    para: "We build innovative apps for wearable devices, keeping users connected while enhancing their experiences with cutting-edge technology.",
  },
  {
    img: require("../../images/mobifive.png"),
    title: "Mobile UI/UX Design",
    para: "Our design-first approach ensures that your app not only looks great but provides a flawless user experience, keeping engagement at its core.",
  },
  {
    img: require("../../images/mobisix.png"),
    title: "App Testing & QA",
    para: "Our thorough testing and QA services ensure that your app is robust, secure, and free from bugs, ready for launch.",
  },
  {
    img: require("../../images/mobiseven.png"),
    title: "Mobile App Maintenance",
    para: "We provide continuous support and maintenance to ensure your app stays updated and performs optimally as technology evolves.",
  },
  {
    img: require("../../images/mobieight.png"),
    title: "App Store Optimization (ASO)",
    para: "We help improve your app’s visibility in app stores, driving organic downloads and enhancing user engagement.",
  },
  // {
  //   img: require("../../images/mobinine.png"),
  //   title: "Website Revamps",
  //   para: "Need a fresh look or added functionality? Our team is ready to give your website a modern makeover.",
  // },
];



export const customData = [
  {
    img: require("../../images/comicon1.png"),
    title: "Enterprise Software Solutions",
    para: "Develop large-scale enterprise software to streamline operations, boost productivity, and improve decision-making.",
  },

  {
    img: require("../../images/comicon2.png"),
    title: "SaaS Development",
    para: "We design and develop scalable SaaS applications that offer flexibility and convenience for your end-users.",
  },
  {
    img: require("../../images/comicon3.png"),
    title: "CRM & ERP Solutions",
    para: "Empower your business with custom CRM and ERP systems that integrate smoothly into your existing processes and data workflows.",
  },
  {
    img: require("../../images/comicon4.png"),
    title: "Business Automation Tools",
    para: "Automate repetitive tasks and workflows with business automation tools, designed to enhance efficiency and reduce human error.",
  },
  {
    img: require("../../images/comicon5.png"),
    title: "Database Design & Development",
    para: "We create optimized and secure databases that are easy to manage and scale as your data grows.",
  },
  {
    img: require("../../images/iconsix.png"),
    title: "Custom API Development",
    para: "Seamlessly integrate your software with third-party applications through custom API development, ensuring smooth communication between systems.",
  },
  {
    img: require("../../images/comicon6.png"),
    title: "Desktop Application Development",
    para: "Build high-performance desktop applications for Windows and Mac, tailored to your specific business needs.",
  },
  {
    img: require("../../images/comicon7.png"),
    title: "Third-Party Integrations",
    para: "Extend the functionality of your existing systems with reliable third-party integrations, ensuring minimal disruptions to your workflows.",
  },
  {
    img: require("../../images/comicon8.png"),
    title: "Software Prototyping & MVP Development",
    para: "Rapidly develop software prototypes or MVPs to test and validate your ideas before full-scale development.",
  },
];


export const blockchainData = [
  {
    img: require("../../images/blockicon1.png"),
    title: "Smart Contract Development (Solidity, Ethereum)",
    para: "Automate agreements with secure, self-executing smart contracts on Ethereum and other blockchain platforms.",
  },

  {
    img: require("../../images/blockicon2.png"),
    title: "Decentralized Apps (DApps) Development",
    para: "Build decentralized applications to leverage the power of blockchain for transparency and security.",
  },
  {
    img: require("../../images/blockicon3.png"),
    title: "Token Development (ERC20, ERC721, BEP20)",
    para: "Create secure and scalable tokens for various blockchain standards including ERC20, ERC721, and BEP20.",
  },
  {
    img: require("../../images/blockicon4.png"),
    title: "Cryptocurrency Wallet Development",
    para: "Develop secure cryptocurrency wallets to manage digital assets with confidence.",
  },
  {
    img: require("../../images/blockicon5.png"),
    title: "Blockchain Consulting & Strategy",
    para: "Gain insights and strategies to implement blockchain solutions tailored to your business goals.",
  },
  {
    img: require("../../images/blockicon6.png"),
    title: "Private Blockchain Development",
    para: "Build customized, permissioned blockchain networks for secure, private transactions.",
  },
  {
    img: require("../../images/blockicon7.png"),
    title: "Blockchain Security Solutions",
    para: "Secure your blockchain infrastructure with our advanced security solutions, ensuring the integrity of your data.",
  },
  {
    img: require("../../images/blockicon8.png"),
    title: "Blockchain for Supply Chain Management",
    para: "Enhance traceability, efficiency, and transparency in supply chain operations with blockchain technology.",
  },
  {
    img: require("../../images/blockicon9.png"),
    title: "DeFi (Decentralized Finance) Solutions",
    para: "Empower your business with DeFi solutions that offer decentralized lending, borrowing, and asset management.",
  },
];


export const IotData = [
  {
    img: require("../../images/ioticon1.png"),
    title: "IoT Hardware Integration",
    para: "Seamlessly connect devices and hardware to create a unified, smart IoT system.",
  },

  {
    img: require("../../images/ioticon2.png"),
    title: "IoT Platform Development",
    para: "Develop scalable IoT platforms that manage devices, process data, and offer insightful analytics.",
  },
  {
    img: require("../../images/ioticon3.png"),
    title: "Smart Home Solutions",
    para: "Build secure, intelligent smart home systems with advanced IoT technology for enhanced convenience and efficiency.",
  },
  {
    img: require("../../images/ioticon4.png"),
    title: "Industrial IoT (IIoT) Solutions",
    para: "Optimize industrial operations through connected IIoT solutions, enabling real-time monitoring and automation.",
  },
  {
    img: require("../../images/ioticon5.png"),
    title: "Wearable IoT Solutions",
    para: "Design wearable IoT solutions that provide seamless connectivity and enhanced user experiences.",
  },
  {
    img: require("../../images/ioticon6.png"),
    title: "IoT Sensor Integration",
    para: "Integrate sensors into your IoT ecosystem for real-time data collection and analysis.",
  },
  {
    img: require("../../images/ioticon7.png"),
    title: "IoT Data Analytics & Insights",
    para: "Leverage IoT data analytics to gain actionable insights and improve decision-making.",
  },
  {
    img: require("../../images/ioticon8.png"),
    title: "Edge Computing Solutions",
    para: "Enable faster processing and reduced latency with edge computing for IoT applications.",
  },
  {
    img: require("../../images/ioticon9.png"),
    title: "IoT Mobile & Web App Development",
    para: "Develop mobile and web applications to control, monitor, and manage IoT devices with ease.",
  },
];

export const digitalData = [
  {
    img: require("../../images/dicon1.png"),
    title: "Search Engine Optimization (SEO)",
    para: "Improve your website’s visibility on search engines, driving more organic traffic.",
  },

  {
    img: require("../../images/dicon2.png"),
    title: "Pay-Per-Click (PPC) Advertising",
    para: "Run targeted PPC campaigns on Google and Bing for instant visibility and higher conversions.",
  },
  {
    img: require("../../images/dicon3.png"),
    title: "Social Media Marketing",
    para: "Build your brand’s presence across social media platforms with targeted strategies.",
  },
  {
    img: require("../../images/dicon4.png"),
    title: "Email Marketing",
    para: "Engage your audience with personalized email campaigns that drive conversions.",
  },
  {
    img: require("../../images/dicon5.png"),
    title: "Content Marketing (Blogging, Articles, E-books)",
    para: "Create valuable content that attracts, engages, and converts your target audience.",
  },
  {
    img: require("../../images/dicon6.png"),
    title: "Affiliate Marketing",
    para: "Expand your reach and generate sales through a network of affiliate partners.",
  },
  {
    img: require("../../images/dicon7.png"),
    title: "Influencer Marketing",
    para: "Collaborate with influencers to promote your products and build trust with new audiences.",
  },
  {
    img: require("../../images/dicon8.png"),
    title: "Conversion Rate Optimization (CRO)",
    para: "Maximize the value of your website traffic by optimizing user experience and increasing conversions.",
  },
  {
    img: require("../../images/dicon9.png"),
    title: "Online Reputation Management (ORM)",
    para: "Protect and enhance your brand’s online reputation with effective reputation management strategies.",
  },
];

export const cloudData = [
  {
    img: require("../../images/cloudicon1.png"),
    title: "Cloud Infrastructure Setup (AWS, Azure, Google Cloud)",
    para: "Set up cloud infrastructure that scales effortlessly, ensuring high availability and performance.",
  },

  {
    img: require("../../images/cloudicon2.png"),
    title: "Cloud Migration Services",
    para: "Seamlessly move your business to the cloud with minimal disruption and maximum security.",
  },
  {
    img: require("../../images/cloudicon3.png"),
    title: "Hybrid Cloud Solutions",
    para: "Combine the best of private and public clouds for increased flexibility and control.",
  },
  {
    img: require("../../images/cloudicon4.png"),
    title: "Cloud-Native Application Development",
    para: "Build scalable, secure applications that leverage cloud-native features for optimal performance.",
  },
  {
    img: require("../../images/cloudicon5.png"),
    title: "Cloud Cost Optimization",
    para: "Maximize your cloud investment by identifying inefficiencies and optimizing costs.",
  },
  {
    img: require("../../images/cloudicon6.png"),
    title: "Cloud Backup & Disaster Recovery",
    para: "Ensure business continuity with secure, automated cloud backups and disaster recovery plans.",
  },
  {
    img: require("../../images/cloudicon7.png"),
    title: "Cloud Monitoring & Maintenance",
    para: "Proactively monitor your cloud infrastructure to ensure performance and reliability.",
  },
  {
    img: require("../../images/cloudicon8.png"),
    title: "Serverless Architecture Development",
    para: "Build and deploy applications without managing servers, reducing operational complexity.",
  },
  {
    img: require("../../images/cloudicon9.png"),
    title: "Cloud DevOps Services (CI/CD Pipelines)",
    para: "Automate and streamline development processes with CI/CD pipelines, ensuring faster release cycles.",
  },
];

export const ItData = [
  {
    img: require("../../images/iticon1.png"),
    title: "IT Strategy Consulting",
    para: "Align your IT strategy with business goals to enhance performance and scalability.",
  },

  {
    img: require("../../images/iticon2.png"),
    title: "Technology Roadmap Development",
    para: "Develop a technology roadmap that guides your digital transformation initiatives.",
  },
  {
    img: require("../../images/iticon3.png"),
    title: "Cloud Infrastructure Consulting",
    para: "Leverage cloud technologies to optimize operations and reduce IT infrastructure costs.",
  },
  {
    img: require("../../images/iticon4.png"),
    title: "IT Budgeting & Cost Optimization",
    para: "Build scalable, secure applications that leverage cloud-native features for optimal performance.",
  },
  {
    img: require("../../images/iticon5.png"),
    title: "Software & Platform Selection",
    para: "Get expert advice on selecting the right software and platforms to meet your business needs.",
  },
  {
    img: require("../../images/iticon6.png"),
    title: "Data & Analytics Consulting",
    para: "Unlock the power of data with tailored analytics strategies that drive informed decision-making.",
  },
  {
    img: require("../../images/iticon7.png"),
    title: "Digital Transformation Consulting",
    para: "Lead your business into the future with digital transformation strategies that modernize operations.",
  },
  {
    img: require("../../images/iticon8.png"),
    title: "IT Project Management",
    para: "Ensure successful IT projects from start to finish with expert project management support.",
  },
  {
    img: require("../../images/iticon9.png"),
    title: "Risk Assessment & Compliance Consulting",
    para: "Identify and mitigate risks while ensuring compliance with industry standards and regulations.",
  },
];

export const uiuxData = [
  {
    img: require("../../images/uiicon1.png"),
    title: "User Research & Personas",
    para: "Understand your target users and create personas to inform design decisions.",
  },

  {
    img: require("../../images/uiicon2.png"),
    title: "Wireframing & Prototyping",
    para: "Visualize your product’s structure with interactive wireframes and prototypes for a refined user experience.",
  },
  {
    img: require("../../images/uiicon3.png"),
    title: "Mobile App UI/UX Design",
    para: "Design user-friendly mobile apps that are both intuitive and visually appealing.",
  },
  {
    img: require("../../images/uiicon4.png"),
    title: "Web UI/UX Design",
    para: "Create seamless and engaging web experiences through responsive and user-focused designs.",
  },
  {
    img: require("../../images/uiicon9.png"),
    title: "Interaction Design",
    para: "Enhance usability with intuitive interactions that keep users engaged.",
  },
  {
    img: require("../../images/uiicon5.png"),
    title: "Usability Testing",
    para: "Test your product’s functionality and ensure it meets user needs effectively.",
  },
  {
    img: require("../../images/uiicon6.png"),
    title: "Design Systems & Style Guides",
    para: "Ensure design consistency with comprehensive design systems and style guides.",
  },
  {
    img: require("../../images/uiicon7.png"),
    title: "Product Design",
    para: "From MVP to full-scale products, we design with scalability and usability in mind.",
  },
  {
    img: require("../../images/uiicon8.png"),
    title: "Responsive Design",
    para: "Guarantee a smooth and consistent experience across all devices with responsive design solutions.",
  },
];
