import { useState } from 'react'
import ApplyNow from '../components/Common/ApplyNow'
import { CurrentPositions } from '../components/Common/PositionItems'
import Positions from '../components/Common/Positions'
import './career.css'
import { Modal } from 'react-bootstrap'
import { RxCross1 } from 'react-icons/rx'

export default function Career (){

  const [modal, setModal] = useState(false)

  return (
    <>
      {/* Banner */}
      <div className="solution-banner position-relative">
        <img
          src={require("../images/career-banner.png")}
          width="100%"
          alt="Banner"
          className="img-fluid carrerbanner"
        />
        <div className="banner-inner text-center text-white">
          <h1 className="mb-lg-4">Careers</h1>
          <p className="pforimac">
            We have come together to build something that brings change to the
            world. Come and join us in this revolution of change through IT.
          </p>
        </div>
      </div>

      {/* Intro */}
      <div className=" intro">
        <div className="container my-1">
          <div className="text-md-end text-center">
            <h1 className="light-heading text-uppercase py-3 ">
              # Our team &nbsp;
            </h1>
            {/* <hr /> */}
          </div>
        </div>
        <div className="container d-md-flex justify-content-between">
          <div className="col-md-5 text">
            <div>
              <h3 className="text-capitalize hed1 mb-4">
                Build together,{" "}
                <span className="text-blue">
                  grow <br className="d-none d-md-block" />
                  together
                </span>
                <br />{" "}
              </h3>
              <p className="mb-4 pforimac">
                We are proud to be a part of innovative company that values
                collaboration, creativity, and respect. We believe that a
                positive and supportive work environment is essential for growth
                and are committed to creating an atmosphere where every employee
                feels valued and appreciated.
              </p>
              {/* <p>A leading <span class="blue-border">IT company</span> dedicated to providing <span class="blue-border">you</span> a <span class="blue-border">custom solution</span> and service that <span class="blue-border">empowers your business</span> to achieve its goals.</p> */}
              <br />
              <p className="mb-3 pforimac">Does this excite you?</p>
              <p className="pforimac">
                So why wait? Join us today and be a part of our team.
              </p>
            </div>
          </div>
          <div className="col-md-6  col-10 mx-auto image1">
            <img
              src={require("../images/team.png")}
              width="100%"
              className="img-fluid"
              loading="lazy "
              alt="Team"
            />
          </div>
        </div>
      </div>

      {/* Content */}
      {CurrentPositions.length === 0 ? (
        <div className="text-center container my-md-5 my-3 no-openings">
          <h2 className="fw-bold mb-4">No New Openings!</h2>
          <p className="mb-3 fs-5 pforimac">
            Currently we don't have any openings yet.
          </p>
          <p className="mb-4 px-md-5 pforimac">
            You can still send us your resume and if we can align well with your
            application, we'll contact you. We're always open to welcoming
            talents and providing them with the opportunity they deserve.
          </p>
          <button
            className="contact-btn text-uppercase py-2 rounded-5"
            onClick={() => {
              setModal(true);
            }}
          >
            Send resume
          </button>
        </div>
      ) : (
        <>
          {/* <div className="openings container d-flex flex-wrap justify-content-between my-md-5 my-4">
            <div className="col-md-7 col-12">
              <h1 className='mb-3'>Open Positions</h1>
              <Positions CurrentPositions={CurrentPositions} />
            </div>
            <div className="col-lg-3 col-md-4 col-12 mx-auto text-center mt-4">
              <h5>Recruitment roadmap</h5>
              <img src={require("../images/roadmap.png")} alt="Roadmap" className="img-fluid w-100" />
            </div>
          </div> */}

          <div className="container text-center">
            <h1>Didn't find what you were looking for?</h1>
            <p className="fw-light my-4 pforimac">Don't worry about it! </p>
            <p className="fw-light px-md-5 px-3 pforimac">
              You can still send us your resume and if we can align well with
              your application, we'll contact you. We're always open to
              welcoming talents and providing them with the opportunity they
              deserve.
            </p>
            <button
              className="contact-btn text-uppercase py-2 px-5 my-4"
              onClick={() => {
                setModal(true);
              }}
            >
              Send resume
            </button>
          </div>
        </>
      )}

      {/* Modal */}
      <Modal show={modal}>
        <Modal.Body className="career-popup">
          <ApplyNow setModal={setModal} />
          <button
            type="button"
            class="btn-close slider-btn cross-btn"
            aria-label="Close"
            onClick={() => {
              setModal(false);
            }}
          >
            <RxCross1 fill="#ffffff" />
          </button>
        </Modal.Body>
      </Modal>
    </>
  );

}