import React from "react"
import "./portfolio.css"
import { PiHandWavingFill } from "react-icons/pi";
import Projects from "../components/Common/Projects"



export default function portfolio () {
    
    return (
      <>
        <div className="">
          {/* Banner  */}
          <div className="solution-banner position-relative">
            <img
              src={require("../images/portfolioImages/portfoliobannerbg.png")}
              width="100%"
              alt="Banner"
              className="img-fluid portbanner"
            />
            <div className="banner-inner text-center text-white">
              <h1 className="mb-lg-4">Portfolio</h1>
              <p className="px-sm-5 pforimac">
                Unlocking Success Stories : Explore Our Diverse Portfolio of
                Transformative Projects
              </p>
            </div>
          </div>

          {/* Right Side  */}
          <div className="container" id="Technology">
            <div className="text-md-end text-center">
              <h1 className="light-heading my-4 "># OUR PROJECTS</h1>
            </div>
          </div>

          <h3 className="text-center  mb-5">Highlights Of Our Work</h3>

          {/* Projects  */}
          <Projects />

          {/* Lets build your ideas */}
          {/* 
            <div className="let-build-yor-idea">
                <div className="container pt-5 pb-5">
                    <div className="d-flex flex-wrap justify-content-between">
                        <div className="col-8">
                            <div className="d-flex flex-wrap"> 
                                <PiHandWavingFill fill="#ffcc33" size={38} />
                                &nbsp; &nbsp;
                                <h1 className="col-8">Want to stay ahead of the competition?</h1>
                                <p className="text-secondary px-5">Discover how we can elevate your success today! Click here to lead the pack.</p>
                            </div>
                        </div>
                        <div className="col-2 text-end">
                          <button style={{backgroundColor:"#2293BB"}} className=" text-white w-100 mt-5  p-2 rounded">Let's Build Your Ideas</button>
                        </div>
                    </div>

                </div>
            </div> */}
        </div>
      </>
    );
}