import React from 'react'
import Thanku from '../components/Common/Thanku'
import Footer from '../components/Footer'
import Header from '../components/Header'

function Thankyou() {
  return (
    <div>
        {/* <Header/> */}
        <Thanku/>
        {/* <Footer/> */}
    </div>
  )
}

export default Thankyou