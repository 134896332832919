import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';

function Static() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []); // Empty dependency array ensures the effect runs on
  return (
    <div>
      <div className=" mb-md-5 pb-5 pb-md-5 bgstats d-md-block d-none">
        <div className="">
          <h1 className="text-center my-3 my-md-3 my-lg-0 p-2">
            Revealing Numbers and Metrics
          </h1>
          <p className="text-center text-secondary px-5 mb-4 mb-md-0 pforimac mt-md-3">
            We push progress and redefine what's possible
          </p>
        </div>
        <div className=" mt-md-4  statsdiv ">
          <div className="d-flex justify-content-evenly px-md-5 me-md-0  ">
            <div className="p-1 ">
              <div className="d-flex flex-wrap justify-content-around">
                <h1 className="stats-color fornumberimac fontsizestats">25+</h1>
              </div>
              <p className="fortextimac">Delivered Projects</p>
            </div>
            <div className="vr  me-md-3"></div>
            <div className="p-1">
              <div className="d-flex flex-wrap justify-content-around">
                <h1 className="stats-color fornumberimac fontsizestats ">5+</h1>
              </div>
              <p className="fortextimac">Ongoing Projects</p>
            </div>
            <div className="vr me-md-3 "></div>
            <div className="p-1 ">
              <div className="d-flex flex-wrap justify-content-around">
                <h1 className="stats-color fornumberimac fontsizestats ">
                  23+
                </h1>
              </div>
              <p className="fortextimac">Happy Clients</p>
            </div>
            <div className="vr  me-md-3 "></div>
            <div className="p-1 ">
              <div className="d-flex flex-wrap justify-content-around">
                <h1 className="stats-color fornumberimac  fontsizestats">
                  15+
                </h1>
              </div>
              <p className="fortextimac">Team Size</p>
            </div>
          </div>
        </div>
      </div>

      {windowWidth < 480 && (
        <>
          <div className="">
            <h1 className="text-center  my-lg-0 p-2">
              Revealing Numbers and Metrics
            </h1>
            <p className="text-center text-secondary px-5 mb-4 mb-md-0 pforimac ">
              We push progress and redefine what's possible
            </p>
          </div>
          <div className=" mt-3 mt-md-4 statsdiv ">
            <div className="text-center">
              <div className="d-flex justify-content-center align-items-center col-md-12  gap-md-5 flex-wrap">
                <div className="col-6">
                  <div>
                    <div className="">
                      <h1 className="stats-color fornumberimac fontsizestats mx-md-0 mx-4">
                        27+
                      </h1>
                    </div>
                    <p className="fortextimac">Delivered Projects</p>
                  </div>

                  <div className="mt-3">
                    <div className="d-flex flex-wrap justify-content-around">
                      <h1 className="stats-color fornumberimac fontsizestats">
                        5+
                      </h1>
                    </div>
                    <p className="fortextimac">Ongoing Projects</p>
                  </div>
                </div>
                <div className="col-6 ">
                  <div className="">
                    <div className="d-flex flex-wrap justify-content-around ">
                      <h1 className="stats-color fornumberimac fontsizestats ">
                        23+
                      </h1>
                    </div>
                    <p className="fortextimac">Happy Clients</p>
                  </div>

                  <div className="mt-3">
                    <div className="d-flex flex-wrap justify-content-around">
                      <h1 className="stats-color fornumberimac fontsizestats">
                        15+
                      </h1>
                    </div>
                    <p className="fortextimac">Team Size</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default Static