import React from 'react'

export default function whatsappConnect() {
    return (
        
        <div id='whatsapp' className="whatsappconnect bounce2 text-center">
            {/* <div className="whats p-2 bg-white rounded mb-1" >
                <p className='text-black'>Get in touch!</p>
            </div> */}
            <a href="https://api.whatsapp.com/send?phone=919552238055" target="_blank" rel="noopener noreferrer" className='wtsapp'>
                <img src={require('../images/whatsapp-logo.png')} alt="Whatsapp Connect" width={50} height={50} />
            </a>
        </div>
    )
}
