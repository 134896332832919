import React, { useEffect, useState } from "react";
import "./Testimonials.css";

import { Swiper, SwiperSlide } from "swiper/react";
import {
  Navigation,
  Autoplay,
  Pagination,
  Scrollbar,
  A11y,
} from "swiper/modules";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

import { AiFillStar } from "react-icons/ai";
import { TestimonialItems } from "./TestimonialItems";

export default function Testimonials() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []); // Empty dependency array ensures the effect runs only once after initial render
  // explore all technologies
  return (
    <>
      {/* for desk */}
      <div className="d-none d-md-block swiper-web-datart">
        <Swiper
          modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
          spaceBetween={10}
          slidesPerView={2}
          autoplay={{ delay: 5000 }}
          speed={2500}
          // navigation
          // pagination={{ clickable : true }}
          // scrollbar={{ draggable: true }}
          navigation={windowWidth > 1900 ? false : true}
          pagination={windowWidth > 1900 ? { clickable: true } : false}
          // style={{ height: "450px" }}
          // style={windowWidth > 2520 ? { height:"650px" ,widows:'800px'} : {height:"450px"}}
          className="testimonials mt-3 mb-4 position-relative "
        >
          {TestimonialItems.map((data, i) => {
            return (
              <SwiperSlide key={i} className="pb-lg-0 pb-md-4">
                <div className="container d-flex flex-wrap justify-content-around">
                  <div className="col-md-12 col-lg-4 testimonial-img p-3">
                    <img
                      src={data.img}
                      alt="Testimonials"
                      width="100%"
                      className="img-fluid rounded-3"
                    />
                  </div>
                  <div className="col-md-12 col-lg-7 testimonial-txt">
                    <p className="mb-2 fw-medium pt-3 pt-md-0 pfortesti">
                      {data.name}{" "}
                      <small className="secondary-light-color">
                        | {data.site}
                      </small>
                    </p>
                    <small
                      className="forimactext"
                      style={{ textAlign: "justify" }}
                    >
                      "{data.text}"
                    </small>
                    {data.stars === 5 && (
                      <p>
                        <AiFillStar fill="#0FA4D2" className="me-1" />
                        <AiFillStar fill="#0FA4D2" className="me-1" />
                        <AiFillStar fill="#0FA4D2" className="me-1" />
                        <AiFillStar fill="#0FA4D2" className="me-1" />
                        <AiFillStar fill="#0FA4D2" className="me-1" />
                      </p>
                    )}
                    {data.stars === 4 && (
                      <p>
                        <AiFillStar fill="#0FA4D2" className="me-1" />
                        <AiFillStar fill="#0FA4D2" className="me-1" />
                        <AiFillStar fill="#0FA4D2" className="me-1" />
                        <AiFillStar fill="#0FA4D2" className="me-1" />
                      </p>
                    )}
                    {data.stars === 3 && (
                      <p>
                        <AiFillStar fill="#0FA4D2" className="me-1" />
                        <AiFillStar fill="#0FA4D2" className="me-1" />
                        <AiFillStar fill="#0FA4D2" className="me-1" />
                      </p>
                    )}
                    {data.stars === 2 && (
                      <p>
                        <AiFillStar fill="#0FA4D2" className="me-1" />
                        <AiFillStar fill="#0FA4D2" className="me-1" />
                      </p>
                    )}
                    {data.stars === 1 && (
                      <p>
                        <AiFillStar fill="#0FA4D2" className="me-1" />
                      </p>
                    )}
                  </div>
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>

      {/* for mobile */}
      {/* <div className=""> */}
      <Swiper
        modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
        // spaceBetween={50}
        slidesPerView={1}
        autoplay={{ delay: 3000 }}
        // navigation
        style={{ height: "600px" }}
        pagination={{ clickable: true }}
        // scrollbar={{ draggable: true }}

        className=" d-lg-none d-sm-block"
      >
        {TestimonialItems.map((data, i) => {
          return (
            <>
              <SwiperSlide key={i}>
                <div className="container d-flex flex-wrap justify-content-around align-items-center ">
                  <div className="col-9 col-md-3 testimonial-img">
                    <img
                      src={data.img}
                      alt="Testimonials"
                      className="img-fluid rounded-4 border "
                    />
                  </div>
                  <div className="col-md-7 testimonial-txt col-12 ">
                    <p className="mb-2 fw-medium pt-3 pt-md-0">
                      {data.name}{" "}
                      <span className="secondary-light-color">
                        | {data.site}
                      </span>
                    </p>
                    <small className="mb-2 pb-2 " style={{ text: "justify" }}>
                      "{data.text}"
                    </small>
                    {data.stars === 5 && (
                      <p>
                        <AiFillStar fill="#0FA4D2" className="me-1" />
                        <AiFillStar fill="#0FA4D2" className="me-1" />
                        <AiFillStar fill="#0FA4D2" className="me-1" />
                        <AiFillStar fill="#0FA4D2" className="me-1" />
                        <AiFillStar fill="#0FA4D2" className="me-1" />
                      </p>
                    )}
                    {data.stars === 4 && (
                      <p>
                        <AiFillStar fill="#0FA4D2" className="me-1" />
                        <AiFillStar fill="#0FA4D2" className="me-1" />
                        <AiFillStar fill="#0FA4D2" className="me-1" />
                        <AiFillStar fill="#0FA4D2" className="me-1" />
                      </p>
                    )}
                    {data.stars === 3 && (
                      <p>
                        <AiFillStar fill="#0FA4D2" className="me-1" />
                        <AiFillStar fill="#0FA4D2" className="me-1" />
                        <AiFillStar fill="#0FA4D2" className="me-1" />
                      </p>
                    )}
                    {data.stars === 2 && (
                      <p>
                        <AiFillStar fill="#0FA4D2" className="me-1" />
                        <AiFillStar fill="#0FA4D2" className="me-1" />
                      </p>
                    )}
                    {data.stars === 1 && (
                      <p>
                        <AiFillStar fill="#0FA4D2" className="me-1" />
                      </p>
                    )}
                  </div>
                </div>
              </SwiperSlide>
            </>
          );
        })}
      </Swiper>
      {/* </div> */}
    </>
  );
}
