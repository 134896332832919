import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';

function Expertise() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []); // Empty dependency array ensures the effect runs on

  return (
    <div>
      <div className="container ">
        <h1 className='mx-md-0 mx-3 mt-md-0 mt-4'>Industries We Serve</h1>
        <p className="text-muted mx-md-0 mx-3 mt-md-0 mt-2">
          Effortlessly manage your cloud and on-premises systems with our
          comprehensive support for infrastructure and applications.
        </p>
        <div className="mt-md-4 mt-4 d-md-block d-none">
          <div className="d-flex flex-wrap gap-md-5 mx-md-0 g">
            <div className="col-md-2 col-6 bg-white text-center shadow-sm mb-3">
              <img
                src={require("../../images/solutionPageImages/expertise/health-care.png")}
                alt="Sector Img"
                className="img-fluid col-md-4 col-5 mt-3"
              />
              <p className="pb-2">Healthcare</p>
            </div>
            <div className="col-md-2 col-6 bg-white text-center shadow-sm mb-3">
              <img
                src={require("../../images/solutionPageImages/expertise/banking.png")}
                alt="Sector Img"
                className="img-fluid col-md-4 col-5 mt-3"
              />
              <p className="pb-2">Banking</p>
            </div>
            <div className="col-md-2 col-6 bg-white text-center shadow-sm mb-3">
              <img
                src={require("../../images/solutionPageImages/expertise/entertainment.png")}
                alt="Sector Img"
                className="img-fluid col-md-4 col-5 mt-3"
              />
              <p className="mt-2">Entertainment</p>
            </div>
            <div className="col-md-2 col-6 bg-white text-center shadow-sm mb-3">
              <img
                src={require("../../images/solutionPageImages/expertise/manufacturing.png")}
                alt="Sector Img"
                className="img-fluid col-md-4 col-8 mt-3"
              />
              <p className="mt-2">Manufacturing</p>
            </div>
            <div className="col-md-2 col-6 bg-white text-center shadow-sm mb-3">
              <img
                src={require("../../images/solutionPageImages/expertise/education.png")}
                alt="Sector Img"
                className="img-fluid col-md-4 col-5 mt-3"
              />
              <p className="">Education</p>
            </div>
          </div>
          <div className="d-flex flex-wrap gap-md-5 mt-md-3 mt-3">
            <div className="col-md-2 col-6 bg-white text-center shadow-sm mb-3 pb-2">
              <img
                src={require("../../images/solutionPageImages/expertise/marketing-and-advertising.png")}
                alt="Sector Img"
                className="img-fluid col-md-4 col-5 mt-3"
              />
              <p className=" mt-3">Marketing & Advertising</p>
            </div>
            <div className="col-md-2 col-6 bg-white text-center shadow-sm mb-3 pb-2">
              <img
                src={require("../../images/solutionPageImages/expertise/it-services.png")}
                alt="Sector Img"
                className="img-fluid col-md-4 col-5 mt-2"
              />
              <p className="pb-2">IT Services</p>
            </div>
            <div className="col-md-2 col-6 bg-white text-center shadow-sm mb-3">
              <img
                src={require("../../images/solutionPageImages/expertise/ecom.png")}
                alt="Sector Img"
                className="img-fluid col-md-4 col-5 mt-2"
              />
              <p className="mt-2">E-commerce</p>
            </div>
            <div className="col-md-2 col-6 bg-white text-center shadow-sm mb-3">
              <img
                src={require("../../images/solutionPageImages/expertise/hospitality.png")}
                alt="Sector Img"
                className="img-fluid col-md-4 col-5 mt-2"
              />
              <p className="mt-1">Hospitality</p>
            </div>
            <div className="col-md-2 col-6 bg-white text-center shadow-sm mb-3">
              <img
                src={require("../../images/solutionPageImages/expertise/iot.png")}
                alt="Sector Img"
                className="img-fluid col-md-4 col-5 mt-2"
              />
              <p className="mt-1">IOT</p>
            </div>
          </div>
        </div>
        {windowWidth < 480 && (
          <>
            <div className="mt-md-4 mt-4 d-md-none ">
              <div className="d-flex  justify-content-between gap-2 ">
                <div className="col-6 bg-white text-center shadow-sm mb-3 ">
                  <img
                    src={require("../../images/solutionPageImages/expertise/health-care.png")}
                    alt="Sector Img"
                    className="img-fluid col-md-4 col-5 mt-3"
                  />
                  <p className="pb-2">Healthcare</p>
                </div>
                <div className=" col-6 bg-white text-center shadow-sm mb-3 ">
                  <img
                    src={require("../../images/solutionPageImages/expertise/banking.png")}
                    alt="Sector Img"
                    className="img-fluid col-md-4 col-5 mt-3"
                  />
                  <p className="pb-2">Banking</p>
                </div>
              </div>
              <div className="d-flex justify-content-between gap-2">
                <div className=" col-6 bg-white text-center shadow-sm mb-3 ">
                  <img
                    src={require("../../images/solutionPageImages/expertise/entertainment.png")}
                    alt="Sector Img"
                    className="img-fluid col-md-4 col-5 mt-3"
                  />
                  <p className="mb-0 pb-2">Entertainment</p>
                </div>
                <div className=" col-6 bg-white text-center shadow-sm mb-3">
                  <img
                    src={require("../../images/solutionPageImages/expertise/manufacturing.png")}
                    alt="Sector Img"
                    className="img-fluid col-md-4 col-5 mt-3"
                  />
                  <p className="mb-0">Manufacturing</p>
                </div>
              </div>
              <div className="d-flex  justify-content-between gap-2">
                <div className=" col-6 bg-white text-center shadow-sm mb-3">
                  <img
                    src={require("../../images/solutionPageImages/expertise/education.png")}
                    alt="Sector Img"
                    className="img-fluid col-md-4 col-5 mt-3"
                  />
                  <p className="mb-0 pb-2">Education</p>
                </div>
                <div className=" col-6 bg-white text-center shadow-sm mb-3">
                  <img
                    src={require("../../images/solutionPageImages/expertise/marketing-and-advertising.png")}
                    alt="Sector Img"
                    className="img-fluid col-md-4 col-5 mt-3"
                  />
                  <p className="mt-3">Marketing & Advertising</p>
                </div>
              </div>
              <div className="d-flex gap-2 justify-content-between mt-md-3 mt-3">
                <div className=" col-6 bg-white text-center shadow-sm mb-3">
                  <img
                    src={require("../../images/solutionPageImages/expertise/it-services.png")}
                    alt="Sector Img"
                    className="img-fluid col-md-4 col-5 mt-3"
                  />
                  <p className="mb-0 pb-2">IT Services</p>
                </div>
                <div className=" col-6 bg-white text-center shadow-sm mb-3">
                  <img
                    src={require("../../images/solutionPageImages/expertise/ecom.png")}
                    alt="Sector Img"
                    className="img-fluid col-md-4 col-5 mt-3"
                  />
                  <p className="mb-0 mt-2">E-commerce</p>
                </div>
              </div>
              <div className="d-flex gap-2 justify-content-between mt-md-3 mt-3">
                <div className="col-md-2 col-6 bg-white text-center shadow-sm mb-3">
                  <img
                    src={require("../../images/solutionPageImages/expertise/hospitality.png")}
                    alt="Sector Img"
                    className="img-fluid col-md-4 col-5 mt-3"
                  />
                  <p className="mb-0 pb-2" >Hospitality</p>
                </div>
                <div className="col-md-2 col-6 bg-white text-center shadow-sm mb-3">
                  <img
                    src={require("../../images/solutionPageImages/expertise/iot.png")}
                    alt="Sector Img"
                    className="img-fluid col-md-4 col-5 mt-3"
                  />
                  <p className="mb-0">IOT</p>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default Expertise