import React, { useEffect, useState } from "react";
import "./Footer.css";

import { AiFillInstagram } from "react-icons/ai";
import { FaFacebookF, FaLinkedinIn } from "react-icons/fa";
import { FaSquareXTwitter } from "react-icons/fa6";

import { Link, useNavigate } from "react-router-dom";
import Logo2 from "../images/Logo2.png";

export default function Footer({ setPath }) {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  
  const navigate=useNavigate();

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []); // Empty dependency array ensures the effect runs only once after initial render


  const handlePagechnge=()=>{
     window.scrollTo(0, 0);
  navigate("/privacy-policy");

  }
  return (
    <div className="footer-bg ">
      <div className="d-md-flex flex-wrap justify-content-around align-items-center foot-pad ">
        {/* Logo  */}
        <div className="col-lg-3 col-md-4 logo-txt mt-2 logo ">
          {windowWidth > 2520 ? (
            <>
              <img
                id="logo1"
                alt=""
                src={Logo2}
                width={74}
                height={74}
                className="rounded-3 me-3 mb-4 "
              />
              <img
                src={require("../images/logo.dark.png")}
                alt="Logo"
                className="img-fluid mb-1 img"
                width="25%"
                id="logoblack"
              />
            </>
          ) : (
            <>
              <img
                id="logo1"
                alt=""
                src={Logo2}
                width={64}
                height={64}
                className="mb-1 rounded-3 me-2 "
              />
              <img
                src={require("../images/logo.dark.png")}
                alt="Logo"
                className="img-fluid mb-1 img"
                width="45%"
              />
            </>
          )}
          {/* <p
            className="mb-0 secondary-light-color pe-md-5 pforimac "
            style={{ textAlign: "justify" }}
          >
            A leading IT company that provides best of technology for your
            business to achieve it's goals.
          </p> */}
        </div>

        {/* Menu  */}
        <div className=" col-md-4 col-12 menulinks">
          <ul className="d-flex flex-wrap justify-content-lg-around list-unstyled">
            <li className="col-4 col-lg-auto text-white py-3">
              <Link
                to="/"
                className="text-decoration-none text-white fontforcontact"
                onClick={() => window.scrollTo(0, 0)}
              >
                Home
              </Link>
            </li>

            <li className="col-4 col-lg-auto text-white py-3">
              <Link
                to="/webdevelopment"
                className="text-decoration-none text-white fontforcontact"
                onClick={() => window.scrollTo(0, 0)}
              >
                Services
              </Link>
            </li>

            <li className="col-4 col-lg-auto text-white py-3">
              <Link
                to="/about-us"
                className="text-decoration-none text-white fontforcontact"
                onClick={() => window.scrollTo(0, 0)}
              >
                About Us
              </Link>
            </li>

            <li className="col-4 col-lg-auto text-white py-3">
              <Link
                to="/portfolio"
                className="text-decoration-none text-white fontforcontact"
                onClick={() => window.scrollTo(0, 0)}
              >
                Portfolio
              </Link>
            </li>

            <li className="col-4 col-lg-auto text-white py-3">
              <Link
                to="/careers"
                className="text-decoration-none text-white fontforcontact"
                onClick={() => window.scrollTo(0, 0)}
              >
                Careers
              </Link>
            </li>

            <li className="col-4 col-lg-auto text-white py-3">
              <Link
                to="/contact-us"
                className="text-decoration-none text-white fontforcontact"
                onClick={() => window.scrollTo(0, 0)}
              >
                Contact Us
              </Link>
            </li>
          </ul>
        </div>

        {/* Social links  */}
        <div className="col-lg-3 col-md-4 text-md-center social-section ">
          <h5 className="text-white ">Social Links</h5>
          <div className="d-flex flex-wrap justify-content-center social ">
            <div className="insta">
              <Link
                to="https://www.instagram.com/datartinfotech/"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Instagram"
              >
                <AiFillInstagram fill="#D3D3D3" />
              </Link>
            </div>
            <div className="facebook">
              <Link
                to="https://www.facebook.com/people/Datart-Solutions/100091543035937/"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Facebook"
              >
                <FaFacebookF fill="#D3D3D3" />
              </Link>
            </div>
            <div className="linkedin">
              <Link
                to="https://in.linkedin.com/company/datart-infotech"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="LinkedIn"
              >
                <FaLinkedinIn fill="#D3D3D3" />
              </Link>
            </div>
            <div className="twitter">
              <Link
                to="https://x.com/i/flow/login?redirect_after_login=%2FDatartInfotech"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="X (Twitter)"
              >
                {/* <img src={require('../images/twitter-svg.png')} alt="Twitter" className='img-fluid w-100 mt-1' />
                 */}
                <FaSquareXTwitter fill="#D3D3D3" size={27} />
              </Link>
            </div>
          </div>
        </div>

      
      </div>

     

      <div className="col-md-12 col-7">   
        <p
        className=" secondary-light-color small-size fw-bold me-md-5 col-md-10 text-end "
        onClick={handlePagechnge}
        style={{ cursor: "pointer" }}
      >    Privacy policy

      </p>
      </div>

      <div className="text-center container">
        <p className="secondary-light-color small-size">
          Copyright 2024. All rights reserved. Designed & developed by Datart
          Infotech
        </p>
      </div>
      {/* </div> */}
    </div>
  );
}
