import React, { useEffect, useState } from "react";
import "./solution.css";

import Technologies from "../components/Common/Technologies";
// import Solutions from "../components/Common/Solutions";
import { useLocation } from "react-router-dom";

const SolutionsPage = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const location = useLocation();
  const currentPath = location.pathname;
  useEffect(() => {
    console.log("currentPath", currentPath);
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [location.pathname]); // Empty dependency array ensures the effect runs only once after initial render
  // explore all technologies
  return (
    <>
      {/* Banner  */}
   

      {/* Services  */}
       

      {/* Technologies */}
      

      {/* Expertise  */}
     
      {/* Banner */}
     
      {/* IT ecosystem */}

     
    </>
  );
};

export default SolutionsPage;
