import React, { useState } from 'react'
import './Projects.css'

import { ProjectItem } from './ProjectItems'
import Popup from './Popup';




export default function Projects() {

    const [modal, setModal] = useState(false)

    const [currentData, setCurrentData] = useState()


    return (
      <div className="container mb-5">
       

        <div className="d-flex flex-wrap">
          {ProjectItem.slice(0)
            .reverse()
            .map((data, i) => (
              <div
                key={i}
                className="col-md-4 px-3 mb-3 "
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setModal(true);
                  setCurrentData(data);
                  // document.body.style.overflow = "hidden";
                }}
              >
                <img src={data.img} className="img-fluid rounded-top" alt={`Image ${i}`} />

                <div className="text-center project-text  col-12 p-2 ">
                  <h5 className="text-head">{data.heading}</h5>
                  <p className="text-inner pforimac">{data.subHeading}</p>
                </div>
              </div>
            ))}
        </div>
        <Popup modal={modal} setModal={setModal} currentData={currentData} />
      </div>
    );
}
