import React from 'react'
import './Thanku.css';
import { Link, useNavigate } from 'react-router-dom';

import { useEffect } from 'react';

function Thanku() {
  const navigate = useNavigate();

  const handleportfolio = () => {
    window.scrollTo(0, 0);
    navigate("/webdevelopment");
  };


  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://www.googletagmanager.com/gtag/js?id=G-CLVC3SS166";
    script.async = true;
    document.body.appendChild(script);
    window.dataLayer = window.dataLayer || [];
    window.gtag = function () {
      window.dataLayer.push(arguments);
    };
    window.gtag("js", new Date());
    window.gtag("config", "G-CLVC3SS166");
    window.gtag("event", "conversion", {
      send_to: "AW-16727072441/IvTHCK_9hN0ZELm9i6g-",
    }); 
    return () => {
      document.body.removeChild(script);
    };
  }, []); 

   useEffect(() => {
     if (window.fbq) {
       window.fbq("track", "Lead");
     } else {
       console.error("Facebook Pixel is not loaded");
     }
   }, []);

  return (
    <div>
      <div className="thank-you-bg">
        <div className="container pt-3">
          <div className='boxforimc'>
          <h1 className="text-center mt-5">Thank You!</h1>
          <div className="text-center ">
            <img
              src={require("../../images/thankyou.png")}
              className="img-fluid col-1"
            />
          </div>
          <h3 className="text-center">
            We appreciate your interest in{" "}
            <span style={{ color: "#017B9F" }}>Datart Infotech.</span> <br />A
            member of our team will be in touch with you shortly.
          </h3>
          <h5 className=" text-center mt-4">
            Learn more about our expertise by browsing through our highlighted
            Services.
          </h5>
         

         
          <div className="d-flex align-items-center justify-content-center">
            <div
              className="btn border col-md-2  mt-md-4 rounded-5 abobutton"
              onClick={handleportfolio}
            >
              Visit Our Services
            </div>
            <div onClick={handleportfolio}>
              <img
                src={require("../../images/homeImages/aboutround.png")}
                className="img-fluid  col-7 mt-md-4 "
              />
            </div>
          </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Thanku