import React, { useState } from "react";
import "./Founders.css";

const FoundersArray = [
  {
    img: require("../images/aboutUsImages/after-banner1.png"),
    title: "Custom Software Development",
    flippedImg: require("../images/aboutUsImages/after-banner1.png"),
    flippedTitle: "Custom Software Development",
    flippedText:
      "You get a customised software developed that helps your business run more efficiently and effectively.",
  },
  {
    img: require("../images/aboutUsImages/after-banner1.png"),
    title: "Web Development",
    flippedImg: require("../images/aboutUsImages/after-banner1.png"),
    flippedTitle: "Web Development",
    flippedText:
      "We provide you stunning web solutions curated especially for you that reflects your brand and meets your business goals.",
  },
];
const Founders = () => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  return (
    <>
      <div class="container my-5 ">
        <div class="row justify-content-center">
          <div class="col-md-4 ">
            <div className="founders-text">
              <h1 className="d-md-block d-none">
                Meet Our <br /> <span className="primary-color">Founders</span>{" "}
              </h1>
              <h1 className="d-md-none d-block">
                Meet Our <span className="primary-color">Founders</span>{" "}
              </h1>
              <p style={{ textAlign: "justify" }}>
                At Datart Infotech, our founders are driven by a shared vision
                to harness technology for transformative impact. Together, they
                lead the company in creating innovative solutions that empower
                businesses in a rapidly evolving digital landscape.
              </p>
            </div>
          </div>
          <div class="col-md-4 ">
            <div class="card-container p-4">
              <div class="card-content">
                <div class="card-front rounded-3">
                  <img
                    src={require("../images/aboutUsImages/Cofounder1.png")}
                    alt=""
                    class="img-fluid w-75 rounded-2 mt-md-4"
                  />
                  <div class="text-center founder-hr">
                    <h4 className="mt-2">Yash Vaibhav Tatiya</h4>
                    <hr className="pb-0 p-0 m-0" />
                    <p className="text-muted m-0 p-0 pb-3">
                      Founder and Director
                    </p>
                  </div>
                </div>
                <div class="card-back rounded-3">
                  <div
                    className="founder-card-flip-text p-3 "
                    style={{ textAlign: "justify" }}
                  >
                    <p className="p-md-3 mt-md-5 mt-4 p-2">
                      Yash Tatiya serves as the Executive Director of Datart
                      Infotech. With a strong background in computer science and
                      business analysis, he is dedicated to transforming the
                      company into a catalyst for technological evolution by
                      building a passionate team that creates innovative
                      solutions and pushes the boundaries of IT services.
                    </p>
                  </div>
                  <div class="text-center founder-hr pb-4">
                    <h4 className="">Yash Vaibhav Tatiya</h4>
                    <hr />
                    <p className="text-muted p-0 m-0 pb-4">
                      Founder and Director
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-4">
            <div class="card-container p-4 ">
              <div class="card-content ">
                <div class="card-front rounded-3">
                  <img
                    src={require("../images/aboutUsImages/Cofounder2.png")}
                    alt=""
                    class="img-fluid w-75 rounded-2 mt-md-4 p-1"
                  />
                  <div class="text-center founder-hr">
                    <h4 className="mt-2">Pragati Vaibhav Tatiya</h4>
                    <hr className="pb-0 p-0 m-0" />
                    <p className="text-muted m-0 p-0 pb-3">Managing Director</p>
                  </div>
                </div>
                <div class="card-back rounded-3">
                  <div
                    className="founder-card-flip-text p-3"
                    style={{ textAlign: "justify" }}
                  >
                    <p className="p-md-3 mt-md-5 mt-4 p-2">
                      Pragati Tatiya is a key figure at Datart Infotech,
                      contributing to the company's mission of empowering
                      businesses in a dynamic digital landscape. Her vision
                      emphasizes creativity and adaptability, ensuring that
                      technology enhances every aspect of life and drives
                      sustainable growth.
                    </p>
                  </div>
                  <div class="text-center founder-hr pb-4">
                    <h4 className="mt-2">Pragati Vaibhav Tatiya</h4>
                    <hr />
                    <p className="text-muted p-0  pb-3">Managing Director</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Founders;

{
  
}
