import React from "react";
import { Modal } from "react-bootstrap";

import "./Popup.css";

import { RxCross1 } from "react-icons/rx";
import { PiArrowUpRightBold } from "react-icons/pi";
import ImageSlider from "./ImageSlider";

export default function Popup({ setModal, modal, currentData }) {
  return (
    <>
      {currentData && (
        <div className="">
          <Modal show={modal} className=" portfolio popup " size="" centered>
            <Modal.Body className="pop-bg d-flex flex-wrap popup-width">
              {/* popup-width */}
              <div className="col-md-6 col-12 align-self-center">
                <ImageSlider popupImg={currentData.popImg} />
              </div>

              <div className="col-md-6 col-12 p-lg-5 p-3 pop-txt">
                <h1 className="new-color mb-2">{currentData.heading}</h1>
                <p className="text-muted mb-3 pforimac">
                  {currentData.subHeading}
                </p>
                {currentData.para.map((data, i) => {
                  return (
                    <p style={{ textAlign: "justify" }} className="pforimac">
                      {data.para}
                    </p>
                  );
                })}

                {/* <a href={currentData.link} target="_blank" rel="noopener noreferrer" className='text-decoration-none primary-color fw-medium fs-5 my-lg-5 my-3 d-block'>
                                Product link <PiArrowUpRightBold />
                            </a> */}
                {currentData.link ? (
                  <a
                    href={currentData.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-decoration-none text-dark fw-medium fs-5 my-lg-5 my-3 d-block "
                  >
                    <div className="d-flex">
                      <div
                        className="btn border col-3  rounded-5 abobutton"
                        // onClick={handleContact}
                      >
                        Visit website
                      </div>
                      <div>
                        <img
                          src={require("../../images/homeImages/aboutround.png")}
                          className="img-fluid  col-7 "
                        />
                      </div>
                    </div>
                  </a>
                ) : null}
                {/* <p className='fw-medium mb-3'>Technologies</p>
                            <p>{currentData.techs}</p> */}
              </div>

              <button
                type="button"
                class="btn-close slider-btn"
                aria-label="Close"
                onClick={() => {
                  setModal(false);
                }}
              >
                <RxCross1 fill="#ffffff" />
              </button>
            </Modal.Body>
          </Modal>
        </div>
      )}
    </>
  );
}
