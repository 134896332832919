import React, { useState } from "react";
import "./ApplyNow.css";
import { toast } from "react-toastify";
import axios from "axios";

export default function ApplyNow({ setModal }) {
  const [formData, setformData] = useState({});
  const [selectedFile, setSelectedFile] = useState(null);
  const [error, setError] = useState('');


  const handleChange = (event) => {
    // console.log(formData);

    const { name, value } = event.target;
    let nameValue = name;
    let valueValue = value;
    setformData({ ...formData, [nameValue]: valueValue });

    if (nameValue === "fullName") {
      // Validate 'name' field (e.g., it should not be empty)
      const errorElement = document.getElementById("nameErrors");
      errorElement.innerHTML =
        valueValue.trim() === "" ? "Name is required" : "";
    } else if (nameValue === "emailId") {
      // Validate 'email' field (e.g., it should be a valid email address)
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      const errorElement = document.getElementById("emailErrors");
      errorElement.innerHTML = !emailPattern.test(valueValue)
        ? "Invalid email address"
        : "";
    } else if (nameValue === "contactNo") {
      // Validate 'contactNo' field (e.g., it should be a 10-digit number)
      const contactNoPattern = /^\d{10}$/;
      const errorElement = document.getElementById("contactNoErrors");
      errorElement.innerHTML = !contactNoPattern.test(valueValue)
        ? "Invalid contact number (10 digits required)"
        : "";
    } else if (nameValue === "file") {
      //Validate 'file' field (e.g., select file)
      const errorFile = document.getElementById("myfiles");
      errorFile.innerHTML = valueValue.trim() === "" ? "Select File" : "";
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      setSelectedFile(file);
      setError('');
    } else {
      setSelectedFile(null);
      setError('Please select a file.');
    }
  };

  const submitForm = async () => {


    const config = {
      headers: {
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
      },
    };

    
    if (selectedFile) {
      // console.log('Selected file:', selectedFile);
    } else {
      setError('Please select a file.');
    }

    if (selectedFile) {
      await axios
      .post(
        process.env.REACT_APP_POST_LINK,
        { ...formData, file: selectedFile },
        config
      )
      .then(res => {
        // console.log('result',res);
        document.getElementById("contactForm1").reset();
        if(setModal) {
          setModal(false);
        }
        toast.success("Your application sent successfully", {
          position: "top-right", // Toast position
          autoClose: 3000, // Duration in milliseconds
          hideProgressBar: false, // Whether to display a progress bar
          closeOnClick: true, // Close toast on click
          pauseOnHover: true, // Pause the autoClose timer on hover
          draggable: true, // Enable dragging to dismiss the toast
          progress: undefined, // Custom progress bar component
        });
      })
      .catch(err => {
        console.log('error',err);
        toast.error("Oops! Something went wrong", {
          position: "top-right", // Toast position
          autoClose: 3000, // Duration in milliseconds
          hideProgressBar: false, // Whether to display a progress bar
          closeOnClick: true, // Close toast on click
          pauseOnHover: true, // Pause the autoClose timer on hover
          draggable: true, // Enable dragging to dismiss the toast
          progress: undefined, // Custom progress bar component
        });
      });
    }
  };

  return (
    <form
      onSubmit={(e) => {
        submitForm();
        e.preventDefault();
      }}
      id="contactForm1"
      className="py-lg-2 py-4 px-md-0 applyNow">
      <p className="mb-2">Enter details here</p>

      <div className="w-100  ">
        <input
          required
          type="text"
          className="w-100 py-2 px-2 rounded-3 border"
          placeholder="Your Name"
          name="fullName"
          onChange={handleChange}
        />
        <small className="error text-danger bg-info" id="nameErrors"></small>
      </div>

      <div className="w-100 mt-2">
        <input
          required
          type="text"
          className="w-100 py-2 px-2 rounded-3 border"
          placeholder="abc@email.com"
          name="emailId"
          onChange={handleChange}
        />
        <small className="error text-danger" id="emailErrors"></small>
      </div>

      <div className="w-100 mt-2">
        <input
          required
          type="number"
          className="w-100 py-2 px-2 rounded-3 border"
          minLength={10}
          maxLength={10}
          placeholder="9876543210"
          name="contactNo"
          onChange={handleChange}
        />
        <small className="error text-danger" id="contactNoErrors"></small>
      </div>

      <div className="w-100 mt-2">
        <textarea
          rows="4"
          cols="50"
          className="w-100 py-2 px-2 rounded-3 border"
          placeholder="Type your message here! (Optional)"
          name="message"
          onChange={handleChange}
        />
        <small className="error text-danger" id="messageErrors"></small>
      </div>

      <div className="w-100 mt-2">
        <input
          type="file"
          id="myfiles"
          accept=".doc,.docx,.ppt,.pptx,.pdf"
          className="w-100 py-2 px-2 rounded-3 border"
          name="file"
          placeholder="Attach resume"
          onChange={handleFileChange}
        />
         {error && <p style={{color:'red'}}>{error}</p>}
      </div>

      <input
        type="submit"
        className="btn mt-4 contact-btn px-5 rounded-5 py-2"
        value="Send Application"
      />
    </form>
  );
}
