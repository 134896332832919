import React from 'react'
import { Webdata } from './CardwebData';

function CardUi({
  dataarr
}) {
  return (
    <div>
      <div className="d-flex flex-wrap p-md-5 p-2">
        {dataarr?.map((data, index) => (
          <div key={index} className="col-md-4 col-12 mb-4 pe-md-3  ">
            <div className=" bg-white shadow-sm p-md-2  rounded-2 d-flex  align-items-start  cardforwebmob" style={{height:"160px"}}>
              <img
                src={data.img}
                alt="Img"
                className="img-fluid col-md-2 col-2 align-top mt-md-2 mt-2 p-md-0 p-2"
              />
              <div className="mx-2 ">
                <h6 className=" pt-2">{data.title}</h6>
                <p className="secondary-light-color  font-small pforimacweb ">
                  {data.para}
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default CardUi