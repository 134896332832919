import React, { useEffect, useState } from "react";
import "./Stats.css";
import icon from "../../images/homeImages/icon-stats.png";
import { FaArrowTrendUp } from "react-icons/fa6";

const Stats = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []); // Empty dependency array ensures the effect runs only once after initial render
  // explore all technologies
  return (
    <>
      <div className=" mt-3 statbgimg pb-md-5  pb-2">
        {/* <div
          className={`${windowWidth > 2520 ? "container-fluid" : "container"}`}
        > */}
        <div className=" py-md-5 mb-md-5 pb-5 pb-md-5 ">
          <div className="">
            <div className="text-center ">
              <img src={icon} className="img-fluid" alt="iconimg" />
            </div>
            <h3 className="text-center my-3 my-md-4 my-lg-0 p-2">
              Revealing Numbers and Metrics
            </h3>
            <p className="text-center text-secondary px-5 mb-4 mb-md-0 pforimac mt-2">
              Our journey of groundbreaking concepts drive progress and redefine
              possibilities!
            </p>
          </div>
          <div className=" mt-3 mt-md-4  statsdiv  d-md-block d-none">
            <div className="text-center">
              <div className="d-flex justify-content-center align-items-center col-md-12  gap-md-5 flex-wrap">
                {/* <div className=""> */}
                  <div className=""> 
                  <div className="">
                    <h1 className="stats-color fornumberimac fontsizestats mx-md-0 mx-4">
                      27+
                    </h1>
                  </div>
                  <p className="fortextimac">Delivered Projects</p>
                  </div>
                {/* </div> */}
                <div className="vr  mx-md-4"></div>
                <div className="">
                  <div className="d-flex flex-wrap justify-content-around">
                    <h1 className="stats-color fornumberimac fontsizestats">
                      5+
                    </h1>
                    {/* <div className="px-2 mx-md-3">
                      <FaArrowTrendUp fill="#0FA4D2" size={40} />{" "}
                    </div> */}
                  </div>
                  <p className="fortextimac">Ongoing Projects</p>
                </div>
                <div className="vr mx-md-4"></div>
                <div className="">
                  <div className="d-flex flex-wrap justify-content-around">
                    <h1 className="stats-color fornumberimac fontsizestats ">
                      23+
                    </h1>
                  
                  </div>
                  <p className="fortextimac">Happy Clients</p>
                </div>
                <div className="vr  mx-md-4"></div>
                <div className="">
                  <div className="d-flex flex-wrap justify-content-around">
                    <h1 className="stats-color fornumberimac fontsizestats">
                      15+
                    </h1>
                    
                  </div>
                  <p className="fortextimac">Team Size</p>
                </div>
                {/* <div className="vr  me-md-3"></div> */}
              </div>
            </div>
          </div>
          {windowWidth < 480 && (
            <>
              <div className=" mt-3 mt-md-4  statsdiv ">
                <div className="text-center">
                  <div className="d-flex justify-content-center align-items-center col-md-12  gap-md-5 flex-wrap">
                    <div className="col-6">
                      <div>
                      <div className="">
                        <h1 className="stats-color fornumberimac fontsizestats mx-md-0 mx-4">
                          27+
                        </h1>
                      </div>
                      <p className="fortextimac">Delivered Projects</p>

                    </div>
                   
                    <div className="mt-3">
                      <div className="d-flex flex-wrap justify-content-around">
                        <h1 className="stats-color fornumberimac fontsizestats">
                          5+
                        </h1>
                      </div>
                      <p className="fortextimac">Ongoing Projects</p>
                    </div>

                  
                    </div>
                    <div className="col-6 ">
                      <div className="">
                        <div className="d-flex flex-wrap justify-content-around ">
                          <h1 className="stats-color fornumberimac fontsizestats ">
                            23+
                          </h1>
                        </div>
                        <p className="fortextimac">Happy Clients</p>
                      </div>
                    
                      <div className="mt-3">
                        <div className="d-flex flex-wrap justify-content-around">
                          <h1 className="stats-color fornumberimac fontsizestats">
                            15+
                          </h1>
                        </div>
                        <p className="fortextimac">Team Size</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
        {/* </div> */}
      </div>
    </>
  );
};
export default Stats;
